import * as bookingv1 from '../../proto/booking/v1/booking_pb'
import * as commonOwner from '../../proto/common/owner_pb'
import * as documentv1 from '../../proto/document/v1/document_pb'
import * as userv1 from '../../proto/iam/v1/user_pb'
import * as invoicev1 from '../../proto/invoicing/v1/invoice_pb'
import * as messagev1 from '../../proto/messaging/v1/messaging_pb'
import * as orderv1 from '../../proto/order/v1/order_pb'
import * as shipmentv1 from '../../proto/shipment/v1/shipment_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

import { timestampToString } from '../../helpers/timestamp'
import { dateTimeFromSeconds } from 'shared/helpers/datetime.helper'

import {
  StoryBookingAcception,
  StoryBookingCreation,
  StoryBookingEvent,
  StoryDocument,
  StoryInvoiceIssued,
  StoryItem,
  StoryMessage,
  StoryOrderCreation,
  StoryOrderEvent,
  StoryShipmentCreation,
} from './types'

export function determineAuthorEmail(
  users: { [key: string]: userv1.User },
  owner?: commonOwner.Owner,
): string {
  const user = owner && users[owner.getUserId()]

  if (user && user.getName() && user.getSurname()) {
    return `${user.getName()} ${user.getSurname()}`
  }
  if (user && user.getEmail()) {
    return user.getEmail()
  }

  return 'Unknown'
}

export function determineAuthorPhone(
  users: { [key: string]: userv1.User },
  owner?: commonOwner.Owner,
): string {
  const user = owner && users[owner.getUserId()]

  if (user && user.getPhone()) {
    return user.getPhone()
  }

  return 'Unknown'
}

export function determineAuthorUserInitials(
  users: { [key: string]: userv1.User },
  owner?: commonOwner.Owner,
): string {
  const user = owner && users[owner.getUserId()]

  if (user && user.getName().length > 0 && user.getSurname().length > 0) {
    return user.getName().charAt(0) + user.getSurname().charAt(0)
  }
  return 'Unknown'
}

export function getUserProfilePicture(
  users: { [key: string]: userv1.User },
  owner?: commonOwner.Owner,
): string {
  const user = owner && users[owner.getUserId()]
  if (user && user?.getImg()) {
    return URL.createObjectURL(new Blob([user.getImg()], { type: 'image/png' }))
  }

  return ''
}

export function simpleFormatTimestamp(timestamp?: Timestamp): string {
  return timestamp ? timestampToString(timestamp) : ''
}

export const sortStoryItems = (storyItems: StoryItem[]): StoryItem[] => {
  return storyItems.sort((a, b) => {
    const hasA = !!a.timestamp
    const hasB = !!b.timestamp
    if (!hasA && !hasB) {
      return 0
    }
    if (!hasA) {
      return 1
    }
    if (!hasB) {
      return -1
    }
    return a.timestamp && b.timestamp && a.timestamp < b.timestamp ? 1 : -1
  })
}

export const storyDocument = (document: documentv1.Document): StoryDocument => {
  const metaData = document.getMetaData()
  return {
    document,
    timestamp: metaData?.getCreatedAt(),
    owner: metaData?.getOwner()?.getUserId() ?? '',
    tag: 'DOCUMENT',
  }
}

export const storyMessage = (message: messagev1.Message): StoryMessage => ({
  message,
  timestamp: message.getCreatedAt(),
  owner: message.getOwner()?.getUserId() ?? '',
  tag: 'MESSAGE',
})

export const storyOrderEvent = (event: orderv1.OrderEvent): StoryOrderEvent => ({
  event,
  timestamp: event.getCreatedAt(),
  owner: '',
  tag: 'ORDER_EVENT',
})

export const storyBookingEvent = (
  event: bookingv1.BookingEvent,
  i: number,
  bookingEventArray: bookingv1.BookingEvent[],
): StoryBookingEvent => {
  const eventDate = dateTimeFromSeconds(event.getEventDate()?.getSeconds() ?? 0)
  const earlierBooking = bookingEventArray.find((bookingEvent) => {
    const sameStatus = bookingEvent.getStatus() === event.getStatus()
    const bookingEventDate = dateTimeFromSeconds(bookingEvent.getEventDate()?.getSeconds() ?? 0)
    return sameStatus && bookingEventDate.toMillis() < eventDate.toMillis()
  })

  return {
    event,
    timestamp: event.getEventDate(),
    owner: '',
    tag: 'BOOKING_EVENT',
    firstOccurrenceOfStatus: !earlierBooking,
  }
}

export const storyShipmentCreation = (shipment: shipmentv1.Shipment): StoryShipmentCreation => ({
  shipment,
  timestamp: shipment.getCreatedAt(),
  owner: shipment.getOwner()?.getUserId() ?? '',
  tag: 'SHIPMENT_CREATION',
})

export const storyBookingCreation = (booking: bookingv1.Booking): StoryBookingCreation => ({
  booking,
  timestamp: booking.getCreatedAt(),
  owner: booking.getOwner()?.getUserId() ?? '',
  tag: 'BOOKING_CREATION',
})

export const storyBookingAcception = (booking: bookingv1.Booking): StoryBookingAcception => ({
  booking,
  timestamp: booking.getAcceptedAt(),
  owner: booking.getAcceptor()?.getUserId() ?? '',
  tag: 'BOOKING_ACCEPTION',
})

export const storyOrderCreation = (order: orderv1.Order): StoryOrderCreation => ({
  order,
  timestamp: order.getCreatedAt(),
  owner: order.getOwner()?.getUserId() ?? '',
  tag: 'ORDER_CREATION',
})

export const storyInvoiceIssued = (invoice: invoicev1.Invoice): StoryInvoiceIssued => ({
  invoice,
  timestamp: invoice.getIssuedAt(),
  owner: invoice.getIssuer()?.getUserId() ?? '',
  tag: 'INVOICE_ISSUED',
})
