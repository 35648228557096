import {
  Action,
  Booking,
  BoxTrailer,
  Colli,
  CustomsHandlingExport,
  CustomsHandlingImport,
  DangerousGoods,
  DateFilter,
  DryLoad,
  ExtraLoading,
  ExtraUnloading,
  HangingGarments,
  LoadingNote,
  OtherRequirement,
  ReceiverNotification,
  Requirement,
  SenderNotification,
  SubmodeItem,
  TailLiftAtLoading,
  TailLiftAtUnloading,
  TemperatureControlled,
  UnloadingNote,
  Volume,
} from 'proto/booking/v1/booking_pb'
import { BookingTemplate } from 'proto/booking/v1/bookingtemplate_pb'
import { TransportMode, TransportSubmode } from 'proto/common/booking_pb'
import { Geolocation } from 'proto/common/geolocation_pb'
import { Owner } from 'proto/common/owner_pb'
import { User } from 'proto/iam/v1/user_pb'
import { Order } from 'proto/order/v1/order_pb'

import { canSeePrice, canSeeShipmentPrice } from './rolePermissions'

// Factors used to calculate from stored values.
export const chargeableWeightFactor = 1000
export const weightFactor = 1000
export const spaceFactor = 1000

// This list is useful because enums can't easily be converted to lists.
export const colliTypes: Array<Colli.Type> = [
  Colli.Type.PLT,
  Colli.Type.EUP,
  Colli.Type.PKT,
  Colli.Type.CRT,
  Colli.Type.RLS,
  Colli.Type.PCS,
  Colli.Type.CLI,
]

export const SourceLabels: { [key: string]: string } = {
  [Booking.Source.INTERNAL]: 'Internal',
  [Booking.Source.INTEREAST]: 'InterEast',
  [Booking.Source.CENTIRO]: 'Centiro',
}

export const serviceSelection: { [key in Booking.ServiceSelection]: string } = {
  [Booking.ServiceSelection.UNSELECTED]: 'Unselected',
  [Booking.ServiceSelection.DIRECT]: 'Direct',
  [Booking.ServiceSelection.MANAGED]: 'Managed',
}

export const colliTypeLabels: { [key: string]: string } = {
  [String(Colli.Type.PLT)]: 'Pallet',
  [String(Colli.Type.EUP)]: 'EUR Pallet',
  [String(Colli.Type.PKT)]: 'Package',
  [String(Colli.Type.CRT)]: 'Carton',
  [String(Colli.Type.RLS)]: 'Roll',
  [String(Colli.Type.PCS)]: 'Pieces',
  [String(Colli.Type.CLI)]: 'Colli',
}

export const volumeLabels: { [key: string]: string } = {
  [String(Volume.Unit.PPL)]: 'PPL',
  [String(Volume.Unit.LDM)]: 'LDM',
  [String(Volume.Unit.CBM)]: 'CBM',
  [String(Volume.Unit.FTL)]: 'FTL',
  [String(Volume.Unit.FCL)]: 'FCL',
}

export const statusLabels: { [key in Booking.Status]: string } = {
  [Booking.Status.NEW]: 'New',
  [Booking.Status.DRAFT]: 'Draft',
  [Booking.Status.QUOTE]: 'Quote',
  [Booking.Status.QUOTED]: 'Quoted',
  [Booking.Status.BOOKED]: 'Booked',
  [Booking.Status.ACCEPTED]: 'Accepted',
  [Booking.Status.IN_TRANSIT]: 'In Transit',
  [Booking.Status.EXCEPTION]: 'Exception',
  [Booking.Status.DELIVERED]: 'Delivered',
  [Booking.Status.CANCELLED]: 'Cancelled',
}

export const sourceLabels: { [key in Booking.Source]: string } = {
  [Booking.Source.UPS]: 'UPS',
  [Booking.Source.SUPPLYSTACK]: 'SupplyStack',
  [Booking.Source.CENTIRO]: 'Centiro',
  [Booking.Source.INTEREAST]: 'InterEast',
  [Booking.Source.INTEREAST_DRAFT]: 'InterEast Draft',
  [Booking.Source.INTERNAL]: 'Internal',
}

export const bookingStatusesLabel: { [key in Booking.Status]: string } = {
  [Booking.Status.NEW]: 'new',
  [Booking.Status.DRAFT]: 'draft',
  [Booking.Status.QUOTE]: 'quote',
  [Booking.Status.QUOTED]: 'quoted',
  [Booking.Status.BOOKED]: 'booked',
  [Booking.Status.ACCEPTED]: 'accepted',
  [Booking.Status.IN_TRANSIT]: 'in_transit',
  [Booking.Status.EXCEPTION]: 'exception',
  [Booking.Status.DELIVERED]: 'delivered',
  [Booking.Status.CANCELLED]: 'cancelled',
}

export const transportModeLabel: { [key in TransportMode]: string } = {
  [TransportMode.UNKNOWN]: 'unknown',
  [TransportMode.AIR]: 'air',
  [TransportMode.SEA]: 'sea',
  [TransportMode.RAIL]: 'rail',
  [TransportMode.ROAD]: 'road',
  [TransportMode.COURIER]: 'courier',
}

export const transportModeStatusCode: { [key: string]: TransportMode } = {
  unknown: TransportMode.UNKNOWN,
  air: TransportMode.AIR,
  sea: TransportMode.SEA,
  rail: TransportMode.RAIL,
  road: TransportMode.ROAD,
  courier: TransportMode.COURIER,
}
export const bookingStatusesCode: { [key: string]: Booking.Status } = {
  new: Booking.Status.NEW,
  draft: Booking.Status.DRAFT,
  quote: Booking.Status.QUOTE,
  quoted: Booking.Status.QUOTED,
  booked: Booking.Status.BOOKED,
  accepted: Booking.Status.ACCEPTED,
  in_transit: Booking.Status.IN_TRANSIT,
  exception: Booking.Status.EXCEPTION,
  delivered: Booking.Status.DELIVERED,
  cancelled: Booking.Status.CANCELLED,
}

export const statusColors: { [key in Booking.Status]: string } = {
  [Booking.Status.NEW]: 'blue',
  [Booking.Status.DRAFT]: 'blue',
  [Booking.Status.QUOTE]: 'blue',
  [Booking.Status.QUOTED]: 'green',
  [Booking.Status.BOOKED]: 'purple',
  [Booking.Status.ACCEPTED]: 'blue',
  [Booking.Status.IN_TRANSIT]: 'orange',
  [Booking.Status.EXCEPTION]: 'red',
  [Booking.Status.DELIVERED]: 'green',
  [Booking.Status.CANCELLED]: 'default',
}

export type RequireTypeString =
  | 'Dangerous Goods'
  | 'Temperature Controlled'
  | 'Dry Load'
  | 'Hanging Garments'
  | 'Box Trailer'
  | 'Tail Lift at Loading'
  | 'Tail Lift at Unloading'
  | 'Notify Sender'
  | 'Notify Receiver'
  | 'Loading Note'
  | 'Unloading Note'
  | 'Extra Loading'
  | 'Extra Unloading'
  | 'Customs Handling Import'
  | 'Customs Handling Export'
  | 'Others'

export const requirements: {
  [key in Requirement.Type]: RequireTypeString
} = {
  [Requirement.Type.DANGEROUS_GOODS]: 'Dangerous Goods',
  [Requirement.Type.TEMPERATURE_CONTROLLED]: 'Temperature Controlled',
  [Requirement.Type.DRY_LOAD]: 'Dry Load',
  [Requirement.Type.HANGING_GARMENTS]: 'Hanging Garments',
  [Requirement.Type.BOX_TRAILER]: 'Box Trailer',
  [Requirement.Type.TAIL_LIFT_AT_LOADING]: 'Tail Lift at Loading',
  [Requirement.Type.TAIL_LIFT_AT_UNLOADING]: 'Tail Lift at Unloading',
  [Requirement.Type.SENDER_NOTIFICATION]: 'Notify Sender',
  [Requirement.Type.RECEIVER_NOTIFICATION]: 'Notify Receiver',
  [Requirement.Type.LOADING_NOTE]: 'Loading Note',
  [Requirement.Type.UNLOADING_NOTE]: 'Unloading Note',
  [Requirement.Type.EXTRA_LOADING]: 'Extra Loading',
  [Requirement.Type.EXTRA_UNLOADING]: 'Extra Unloading',
  [Requirement.Type.CUSTOMS_HANDLING_IMPORT]: 'Customs Handling Import',
  [Requirement.Type.CUSTOMS_HANDLING_EXPORT]: 'Customs Handling Export',
  [Requirement.Type.OTHER]: 'Others',
}

type DangerousGoodsClassString =
  | 'Not Dangerous'
  | 'Class 1: Explosives'
  | 'Class 2: Gases'
  | 'Class 3: Flammable Liquids'
  | 'Class 4: Flammable Solids'
  | 'Class 5: Oxidizing Agents and Organic Peroxides'
  | 'Class 6: Toxic and Infectious Substances'
  | 'Class 7: Radioactive Substances'
  | 'Class 8: Corrosive Substances'
  | 'Class 9: Miscellaneous'

export const dangerousGoods: {
  [key in DangerousGoods.Class]: DangerousGoodsClassString
} = {
  [DangerousGoods.Class.EXPLOSIVES]: 'Class 1: Explosives',
  [DangerousGoods.Class.GASES]: 'Class 2: Gases',
  [DangerousGoods.Class.FLAMMABLE_LIQUIDS]: 'Class 3: Flammable Liquids',
  [DangerousGoods.Class.FLAMMABLE_SOLIDS]: 'Class 4: Flammable Solids',
  [DangerousGoods.Class.OXIDIZING_AGENTS_AND_ORGANIC_PEROXIDES]:
    'Class 5: Oxidizing Agents and Organic Peroxides',
  [DangerousGoods.Class.TOXIC_AND_INFECTIOUS_SUBSTANCES]:
    'Class 6: Toxic and Infectious Substances',
  [DangerousGoods.Class.RADIOACTIVE_SUBSTANCES]: 'Class 7: Radioactive Substances',
  [DangerousGoods.Class.CORROSIVE_SUBSTANCES]: 'Class 8: Corrosive Substances',
  [DangerousGoods.Class.MISCELLANEOUS]: 'Class 9: Miscellaneous',
}

export const dangerousGoodsDivisions: {
  [key in DangerousGoods.Class]: string[]
} = {
  [DangerousGoods.Class.EXPLOSIVES]: [
    'Division 1.1: Substances and articles which have a mass explosion hazard',
    'Division 1.2: Substances and articles which have a projection hazard but not a mass explosion hazard',
    'Division 1.3: Substances and articles which have a fire hazard and either a minor blast hazard or a minor projection hazard or both, but not a mass explosion hazard',
    'Division 1.4: Substances and articles which present no significant hazard',
    'Division 1.5: Very insensitive substances which have a mass explosion hazard',
    'Division 1.6: Extremely insensitive articles which do not have a mass explosion hazard',
  ],
  [DangerousGoods.Class.GASES]: [
    'Division 2.1: Flammable gases',
    'Division 2.2: Non-flammable, non-toxic gases',
    'Division 2.3: Toxic gases',
  ],
  [DangerousGoods.Class.FLAMMABLE_LIQUIDS]: [],
  [DangerousGoods.Class.FLAMMABLE_SOLIDS]: [
    'Division 4.1: Flammable solids, self-reactive substances, solid desensitized explosives and polymerizing substances',
    'Division 4.2: Substances liable to spontaneous combustion',
    'Division 4.3: Substances which in contact with water emit flammable gases',
  ],
  [DangerousGoods.Class.OXIDIZING_AGENTS_AND_ORGANIC_PEROXIDES]: [
    'Division 5.1: Oxidizing substances',
    'Division 5.2: Organic peroxides',
  ],
  [DangerousGoods.Class.TOXIC_AND_INFECTIOUS_SUBSTANCES]: [
    'Division 6.1: Toxic substances',
    'Division 6.2: Infectious substances',
  ],
  [DangerousGoods.Class.RADIOACTIVE_SUBSTANCES]: [],
  [DangerousGoods.Class.CORROSIVE_SUBSTANCES]: [],
  [DangerousGoods.Class.MISCELLANEOUS]: [],
}

export type TransportModeType = 'Unspecified' | 'Air' | 'Sea' | 'Rail' | 'Road' | 'Courier'

export const modeEnumToModeType: {
  [key in TransportMode]: TransportModeType
} = {
  [TransportMode.UNKNOWN]: 'Unspecified',
  [TransportMode.AIR]: 'Air',
  [TransportMode.SEA]: 'Sea',
  [TransportMode.RAIL]: 'Rail',
  [TransportMode.ROAD]: 'Road',
  [TransportMode.COURIER]: 'Courier',
}

export type ColliColType = 'amount' | 'space' | 'dimensions' | 'gross_weight' | 'description'
export type ColliColRequirement = boolean
export type ColliCols = { [key in ColliColType]: ColliColRequirement }

export const modeCols: { [key in TransportMode]: ColliCols } = {
  [TransportMode.UNKNOWN]: {
    amount: true,
    dimensions: true,
    space: false,
    gross_weight: true,
    description: false,
  },
  [TransportMode.AIR]: {
    amount: true,
    dimensions: true,
    space: false,
    gross_weight: true,
    description: false,
  },
  [TransportMode.SEA]: {
    amount: true,
    dimensions: false,
    space: false,
    gross_weight: true,
    description: false,
  },
  [TransportMode.RAIL]: {
    amount: true,
    dimensions: true,
    space: false,
    gross_weight: true,
    description: false,
  },
  [TransportMode.ROAD]: {
    amount: true,
    dimensions: false,
    space: false,
    gross_weight: true,
    description: false,
  },
  [TransportMode.COURIER]: {
    amount: true,
    dimensions: true,
    space: false,
    gross_weight: true,
    description: true,
  },
}

export const modeVolumeUnits: {
  [key in TransportMode]: Array<Volume.Unit>
} = {
  [TransportMode.UNKNOWN]: [Volume.Unit.PPL, Volume.Unit.LDM, Volume.Unit.CBM, Volume.Unit.FCL],
  [TransportMode.AIR]: [],
  [TransportMode.SEA]: [Volume.Unit.PPL, Volume.Unit.LDM, Volume.Unit.CBM, Volume.Unit.FCL],
  [TransportMode.RAIL]: [Volume.Unit.PPL, Volume.Unit.LDM, Volume.Unit.CBM],
  [TransportMode.ROAD]: [Volume.Unit.PPL, Volume.Unit.LDM, Volume.Unit.CBM, Volume.Unit.FTL],
  [TransportMode.COURIER]: [],
}

export const modeRequirements: {
  [key in TransportMode]: Array<Requirement.Type>
} = {
  [TransportMode.UNKNOWN]: [
    Requirement.Type.DANGEROUS_GOODS,
    Requirement.Type.TEMPERATURE_CONTROLLED,
    Requirement.Type.DRY_LOAD,
    Requirement.Type.HANGING_GARMENTS,
    Requirement.Type.BOX_TRAILER,
    Requirement.Type.TAIL_LIFT_AT_LOADING,
    Requirement.Type.TAIL_LIFT_AT_UNLOADING,
    Requirement.Type.SENDER_NOTIFICATION,
    Requirement.Type.RECEIVER_NOTIFICATION,
    Requirement.Type.LOADING_NOTE,
    Requirement.Type.UNLOADING_NOTE,
    Requirement.Type.EXTRA_LOADING,
    Requirement.Type.EXTRA_UNLOADING,
    Requirement.Type.CUSTOMS_HANDLING_IMPORT,
    Requirement.Type.CUSTOMS_HANDLING_EXPORT,
    Requirement.Type.OTHER,
  ],
  [TransportMode.AIR]: [
    Requirement.Type.DANGEROUS_GOODS,
    Requirement.Type.TEMPERATURE_CONTROLLED,
    Requirement.Type.DRY_LOAD,
    Requirement.Type.SENDER_NOTIFICATION,
    Requirement.Type.RECEIVER_NOTIFICATION,
    Requirement.Type.LOADING_NOTE,
    Requirement.Type.UNLOADING_NOTE,
    Requirement.Type.CUSTOMS_HANDLING_IMPORT,
    Requirement.Type.CUSTOMS_HANDLING_EXPORT,
    Requirement.Type.OTHER,
  ],
  [TransportMode.SEA]: [
    Requirement.Type.DANGEROUS_GOODS,
    Requirement.Type.TEMPERATURE_CONTROLLED,
    Requirement.Type.DRY_LOAD,
    Requirement.Type.SENDER_NOTIFICATION,
    Requirement.Type.RECEIVER_NOTIFICATION,
    Requirement.Type.LOADING_NOTE,
    Requirement.Type.UNLOADING_NOTE,
    Requirement.Type.CUSTOMS_HANDLING_IMPORT,
    Requirement.Type.CUSTOMS_HANDLING_EXPORT,
    Requirement.Type.OTHER,
  ],
  [TransportMode.RAIL]: [
    Requirement.Type.DANGEROUS_GOODS,
    Requirement.Type.TEMPERATURE_CONTROLLED,
    Requirement.Type.DRY_LOAD,
    Requirement.Type.SENDER_NOTIFICATION,
    Requirement.Type.RECEIVER_NOTIFICATION,
    Requirement.Type.LOADING_NOTE,
    Requirement.Type.UNLOADING_NOTE,
    Requirement.Type.CUSTOMS_HANDLING_IMPORT,
    Requirement.Type.CUSTOMS_HANDLING_EXPORT,
    Requirement.Type.OTHER,
  ],
  [TransportMode.ROAD]: [
    Requirement.Type.DANGEROUS_GOODS,
    Requirement.Type.TEMPERATURE_CONTROLLED,
    Requirement.Type.DRY_LOAD,
    Requirement.Type.HANGING_GARMENTS,
    Requirement.Type.BOX_TRAILER,
    Requirement.Type.TAIL_LIFT_AT_LOADING,
    Requirement.Type.TAIL_LIFT_AT_UNLOADING,
    Requirement.Type.SENDER_NOTIFICATION,
    Requirement.Type.RECEIVER_NOTIFICATION,
    Requirement.Type.LOADING_NOTE,
    Requirement.Type.UNLOADING_NOTE,
    Requirement.Type.EXTRA_LOADING,
    Requirement.Type.EXTRA_UNLOADING,
    Requirement.Type.CUSTOMS_HANDLING_IMPORT,
    Requirement.Type.CUSTOMS_HANDLING_EXPORT,
    Requirement.Type.OTHER,
  ],
  [TransportMode.COURIER]: [
    Requirement.Type.DANGEROUS_GOODS,
    Requirement.Type.TEMPERATURE_CONTROLLED,
    Requirement.Type.DRY_LOAD,
    Requirement.Type.SENDER_NOTIFICATION,
    Requirement.Type.RECEIVER_NOTIFICATION,
    Requirement.Type.LOADING_NOTE,
    Requirement.Type.UNLOADING_NOTE,
    Requirement.Type.CUSTOMS_HANDLING_IMPORT,
    Requirement.Type.CUSTOMS_HANDLING_EXPORT,
    Requirement.Type.OTHER,
  ],
}

export const actionTypeLabels: { [key in Action.Type]: string } = {
  [Action.Type.UNKNOWN]: 'Unknown',
  [Action.Type.ADVISE]: 'Advise',
  [Action.Type.BOOKING_RECEIVED]: 'Booking Received',
  [Action.Type.BOOK_DOMESTIC]: 'Book Domestic',
  [Action.Type.BOOK_LINEHAUL]: 'Book Linehaul',
  [Action.Type.BOOK_SLOT_TIME]: 'Book Slot Time',
  [Action.Type.CONTROLLING]: 'Controlling',
  [Action.Type.CUSTOMS]: 'Customs',
  [Action.Type.INVOICE]: 'Invoice',
}

export function bookMultiOrdersValidationFailures(
  selectedOrders: ReadonlyArray<Order>,
  user: User | undefined,
): ReadonlyArray<string> {
  const validSelectedOrders = selectedOrders.filter((o) => o.getBookingRef() === '')
  const errs = []
  if (!user) {
    return ['A user account is required to create bookings.']
  }
  const roles = user.getRolesList()
  validSelectedOrders.length === selectedOrders.length ||
    errs.push('Cannot create booking without valid orders.')
  validSelectedOrders.every((o) => {
    const po = o.getPurchase()
    const ro = o.getReturn()
    return (po && o.getStatus() === Order.Status.APPROVED) || !!ro
  }) || errs.push('Cannot create booking with unapproved orders.')
  const purchaseOrders = validSelectedOrders.filter((o) => o.getPurchase())
  const returnOrders = validSelectedOrders.filter((o) => o.getReturn())
  if (!(purchaseOrders.length === 0 || returnOrders.length === 0)) {
    errs.push('Cannot create bookings for purchase and return orders together')
  }

  const firstOrder = validSelectedOrders[0]
  if (firstOrder) {
    // Might seem like duplicate logic but these reflect different flows than can change independently.
    const po = firstOrder.getPurchase()
    if (po) {
      roles.indexOf(User.Role.SHIPPER) >= 0 ||
        roles.indexOf(User.Role.ORGANIZATION) >= 0 ||
        roles.indexOf(User.Role.TRANSPORTER) >= 0 ||
        errs.push('Your account lacks permission to create bookings linking to purchase orders.')
    }
    const ro = firstOrder.getReturn()
    if (ro) {
      roles.indexOf(User.Role.SHIPPER) >= 0 ||
        roles.indexOf(User.Role.ORGANIZATION) >= 0 ||
        roles.indexOf(User.Role.TRANSPORTER) >= 0 ||
        errs.push('Your account lacks permission to create bookings linking to return orders.')
    }
  }

  return errs
}

export function getModeRequirements(modes: Array<TransportMode>): Array<Requirement.Type> {
  return modes.reduce<Array<Requirement.Type>>((acc, mode) => {
    return [...acc, ...modeRequirements[mode].filter((val) => !acc.some((a) => a === val))]
  }, [])
}

export function getModeCols(modes: Array<TransportMode>): ColliCols {
  return modes.reduce<ColliCols>(
    (acc, tm) => {
      return {
        amount: acc.amount || modeCols[tm].amount,
        dimensions: acc.dimensions || modeCols[tm].dimensions,
        space: acc.space || modeCols[tm].space,
        gross_weight: acc.gross_weight || modeCols[tm].gross_weight,
        description: acc.description || modeCols[tm].description,
      }
    },
    {
      amount: false,
      dimensions: false,
      space: false,
      gross_weight: false,
      description: false,
    },
  )
}

export function nextStatus(status: Booking.Status): Booking.Status[] | undefined {
  const mappings = {
    [Booking.Status.NEW]: [Booking.Status.DRAFT],
    [Booking.Status.DRAFT]: undefined,
    [Booking.Status.QUOTE]: undefined,
    [Booking.Status.QUOTED]: undefined,
    [Booking.Status.BOOKED]: undefined,
    [Booking.Status.ACCEPTED]: [Booking.Status.IN_TRANSIT, Booking.Status.CANCELLED],
    [Booking.Status.IN_TRANSIT]: [
      Booking.Status.IN_TRANSIT,
      Booking.Status.DELIVERED,
      Booking.Status.EXCEPTION,
    ],
    [Booking.Status.EXCEPTION]: [
      Booking.Status.IN_TRANSIT,
      Booking.Status.DELIVERED,
      Booking.Status.CANCELLED,
    ],
    [Booking.Status.DELIVERED]: undefined,
    [Booking.Status.CANCELLED]: undefined,
  }
  return mappings[status] || undefined
}

export function newBooking(): Booking {
  const b = new Booking()
  b.setOwner(new Owner())

  b.setRequirementsList(new Array<Requirement>())

  return b
}

export function newBookingTemplate(): BookingTemplate {
  return new BookingTemplate()
}

export function applyTemplate(b: Booking, bt: BookingTemplate) {
  b.setOwner(bt.getOwner())
  b.setBookingTemplateId(bt.getBookingTemplateId())
  b.setDesiredTransportModesList(bt.getDesiredTransportModesList())
  b.setTransportSubmodeItemsList(bt.getTransportSubmodeItemsList())
  b.setTransportSubmodeType(bt.getTransportSubmodeType())
  b.setRequirementsList(bt.getRequirementsList())
}

export function validateBookingMode(modes: TransportMode[]): string[] {
  const validateModeForColli: string[] = []

  if (modes.length < 1) {
    validateModeForColli.push('You must select a transport mode')
  }

  return validateModeForColli
}

export function validateBooking(
  booking: Booking,
  transportMode?: TransportMode,
  transportSubmode?: TransportSubmode,
): string[] {
  const invalidFields: string[] = []

  if (booking.getOwner() === undefined || booking.getOwner()?.getOrganizationId() === 0) {
    invalidFields.push('You must select an organization')
  }

  if (booking.getDesiredTransportModesList().length < 1) {
    invalidFields.push('You must select a transport mode')
  }

  booking.getRequirementsList().forEach((req) => {
    switch (req.getType()) {
      case Requirement.Type.DANGEROUS_GOODS:
        if (req.getDangerousGoods() === undefined) {
          invalidFields.push('Invalid value for Dangerous Goods Class')
        } else {
          switch (req.getDangerousGoods()?.getClass()) {
            case DangerousGoods.Class.EXPLOSIVES:
            case DangerousGoods.Class.GASES:
            case DangerousGoods.Class.FLAMMABLE_SOLIDS:
            case DangerousGoods.Class.OXIDIZING_AGENTS_AND_ORGANIC_PEROXIDES:
            case DangerousGoods.Class.TOXIC_AND_INFECTIOUS_SUBSTANCES:
              if (req.getDangerousGoods()?.getDivision() === '') {
                invalidFields.push('Invalid value for Dangerous Goods Division')
              }
              break
          }
        }
        break
      case Requirement.Type.TEMPERATURE_CONTROLLED:
        if (
          req.getTemperatureControlled() === undefined ||
          req.getTemperatureControlled()?.getTemperature() === ''
        ) {
          invalidFields.push('Invalid value for Temperature Controlled')
        }
        break
      case Requirement.Type.DRY_LOAD:
        if (req.getDryLoad() === undefined) {
          invalidFields.push('Invalid value for Dry Load')
        }
        break
      case Requirement.Type.HANGING_GARMENTS:
        if (req.getHangingGarments() === undefined) {
          invalidFields.push('Invalid value for Hanging Garments')
        }
        break
      case Requirement.Type.OTHER:
        if (
          req.getOtherRequirement() === undefined ||
          req.getOtherRequirement()?.getInfo() === ''
        ) {
          invalidFields.push('Invalid value for Other Requirement')
        }
        break
    }
  })

  invalidFields.push(
    ...validateSubModeItemList(
      booking.getTransportSubmodeItemsList(),
      transportMode ? [transportMode] : booking.getDesiredTransportModesList(),
      transportSubmode ? transportSubmode : booking.getTransportSubmodeType(),
    ),
    ...validateColliAndSubmodeItemsAlignments(booking.getTransportSubmodeItemsList()),
  )

  return invalidFields
}

export function validateTemplates(
  template: BookingTemplate,
  currentUser?: User,
  transportMode?: TransportMode,
  transportSubmode?: TransportSubmode,
): string[] {
  const invalidFields: string[] = []

  if (template.getOwner() === undefined && currentUser?.getOrganizationId() === 0) {
    invalidFields.push('You must select an organization')
  }

  if (template.getName() === '') {
    invalidFields.push('You must select a name')
  }

  if (template.getDesiredTransportModesList().length < 1) {
    invalidFields.push('You must select a transport mode')
  }

  template.getRequirementsList().forEach((req) => {
    switch (req.getType()) {
      case Requirement.Type.DANGEROUS_GOODS:
        if (req.getDangerousGoods() === undefined) {
          invalidFields.push('Invalid value for Dangerous Goods Class')
        } else {
          switch (req.getDangerousGoods()?.getClass()) {
            case DangerousGoods.Class.EXPLOSIVES:
            case DangerousGoods.Class.GASES:
            case DangerousGoods.Class.FLAMMABLE_SOLIDS:
            case DangerousGoods.Class.OXIDIZING_AGENTS_AND_ORGANIC_PEROXIDES:
            case DangerousGoods.Class.TOXIC_AND_INFECTIOUS_SUBSTANCES:
              if (req.getDangerousGoods()?.getDivision() === '') {
                invalidFields.push('Invalid value for Dangerous Goods Division')
              }
              break
          }
        }
        break
      case Requirement.Type.TEMPERATURE_CONTROLLED:
        if (
          req.getTemperatureControlled() === undefined ||
          req.getTemperatureControlled()?.getTemperature() === ''
        ) {
          invalidFields.push('Invalid value for Temperature Controlled')
        }
        break
      case Requirement.Type.DRY_LOAD:
        if (req.getDryLoad() === undefined) {
          invalidFields.push('Invalid value for Dry Load')
        }
        break
      case Requirement.Type.HANGING_GARMENTS:
        if (req.getHangingGarments() === undefined) {
          invalidFields.push('Invalid value for Hanging Garments')
        }
        break
      case Requirement.Type.OTHER:
        if (
          req.getOtherRequirement() === undefined ||
          req.getOtherRequirement()?.getInfo() === ''
        ) {
          invalidFields.push('Invalid value for Other Requirement')
        }
        break
    }
  })

  invalidFields.push(
    ...validateSubModeItemList(
      template.getTransportSubmodeItemsList(),
      transportMode ? [transportMode] : template.getDesiredTransportModesList(),
      transportSubmode ? transportSubmode : template.getTransportSubmodeType(),
    ),
    ...validateColliAndSubmodeItemsAlignments(template.getTransportSubmodeItemsList()),
  )

  return invalidFields
}

export function validateColliList(
  colliList: Array<Colli>,
  transportModes: Array<TransportMode>,
): string[] {
  const invalidFields = []

  // Require at least one colli row for AIR and COURIER
  if (
    colliList.length === 0 &&
    (transportModes[0] === TransportMode.AIR || transportModes[0] === TransportMode.COURIER)
  ) {
    invalidFields.push('No colli items')
  }

  const manCols = getModeCols(transportModes)
  colliList.forEach((c, i) => {
    i++
    if (manCols['amount'] && c.getQuantity() === 0) {
      invalidFields.push(`Invalid amount for colli ${i}`)
    }
    const d = c.getDimension()
    if (
      manCols['dimensions'] &&
      (!d || d.getWidth() === 0 || d.getLength() === 0 || d.getHeight() === 0)
    ) {
      invalidFields.push(`Invalid dimension for colli ${i}`)
    }
    const v = c.getVolume()
    if (manCols['space'] && (!v || v.getValue() === 0)) {
      invalidFields.push(`Invalid space for colli ${i}`)
    }
    if (manCols['gross_weight'] && c.getWeight() === 0) {
      invalidFields.push(`Invalid weight for colli ${i}`)
    }
    if (manCols['description'] && c.getDescription() === '') {
      invalidFields.push(`Missing description for colli ${i}`)
    }
  })

  return invalidFields
}

export function validateColliAndSubmodeItemAlignment(submodeItem: SubmodeItem): string[] {
  const invalidFields: string[] = []

  const colliList = submodeItem.getColliList()
  const weight = Math.floor(getColliListTotalWeight(colliList) * weightFactor)
  const volume = getColliListTotalDimensionAsCBM(colliList) * spaceFactor
  const area = getColliListTotalAreaAsLDM(colliList) * spaceFactor
  const totalSpaceUnit = submodeItem.getTotalSpaceUnit()

  if (totalSpaceUnit === SubmodeItem.SpaceUnit.LDM && area > submodeItem.getTotalSpace()) {
    invalidFields.push(`Inputted item space exceeds the total space`)
  } else if (volume > submodeItem.getTotalSpace() && totalSpaceUnit !== SubmodeItem.SpaceUnit.LDM) {
    invalidFields.push(`Inputted item volume exceeds the total space`)
  }

  if (weight > submodeItem.getTotalWeight()) {
    invalidFields.push(`Inputted item weight exceeds the total weight`)
  }

  return invalidFields
}

function validateColliAndSubmodeItemsAlignments(submodeItems: Array<SubmodeItem>): string[] {
  const invalidFields: string[] = []
  if (submodeItems.length === 0) {
    return invalidFields
  }

  submodeItems.forEach((item, i) => {
    const invalidItemFields = validateColliAndSubmodeItemAlignment(item)
    invalidFields.push(...invalidItemFields.map((message) => `${message} for item ${i + 1}`))
  })

  return invalidFields
}

export function validateSubModeItemList(
  submodeItems: Array<SubmodeItem>,
  transportModes: TransportMode[],
  transportSubmode: TransportSubmode,
): string[] {
  const invalidFields = []
  if (
    submodeItems.length > 1 &&
    (transportSubmode === TransportSubmode.PART_LOAD ||
      transportSubmode === TransportSubmode.LESS_CONTAINER_LOAD ||
      transportSubmode === TransportSubmode.UNSPECIFIED)
  ) {
    invalidFields.push(`${transportSubmodeTypeLabel[transportSubmode]} cannot have multiple rows.`)
  }
  const manCols = submodeColReqs[transportSubmode]
  submodeItems.forEach((s, i) => {
    i++

    if (manCols['total_weight'].required && s.getTotalWeight() === 0) {
      invalidFields.push(`Invalid weight for ${transportSubmodeTypeLabel[transportSubmode]} ${i}`)
    }
    if (manCols['space'].required && s.getTotalSpace() === 0) {
      invalidFields.push(`Invalid space for ${transportSubmodeTypeLabel[transportSubmode]} ${i}`)
    }
    if (manCols['space_unit'].required && s.getTotalSpaceUnit() === undefined) {
      invalidFields.push(
        `Invalid space unit type for ${transportSubmodeTypeLabel[transportSubmode]} ${i}`,
      )
    }
    if (manCols['chargeable_weight'].required && s.getTotalChargeableWeight() === 0) {
      invalidFields.push(
        `Invalid chargeable weight for ${transportSubmodeTypeLabel[transportSubmode]} ${i}`,
      )
    }
    if (manCols['container_type'].required && s.getContainerType() === 0) {
      invalidFields.push(`Invalid container type for container ${i}`)
    }
    invalidFields.push(...validateColliList(s.getColliList(), transportModes))
  })

  return invalidFields
}

export function getRequirementDefaultValue(reqType: Requirement.Type, booking: Booking): string {
  switch (reqType) {
    case Requirement.Type.SENDER_NOTIFICATION:
      const senderPhone = booking
        .getRequirementsList()
        .find((r) => r.getSenderNotification()?.getPhoneNumber())
        ?.getSenderNotification()
        ?.getPhoneNumber()
      return senderPhone === undefined ? '' : senderPhone

    case Requirement.Type.RECEIVER_NOTIFICATION:
      const receiverPhone = booking
        .getRequirementsList()
        .find((r) => r.getReceiverNotification()?.getPhoneNumber())
        ?.getReceiverNotification()
        ?.getPhoneNumber()
      return receiverPhone === undefined ? '' : receiverPhone
    default:
      return ''
  }
}

export function newRequirement(
  type: Requirement.Type,
  value: () => string,
  dgClass?: DangerousGoods.Class,
  dgDiv?: string,
): Requirement {
  const req = new Requirement()
  switch (type) {
    case Requirement.Type.DANGEROUS_GOODS:
      if (dgClass === undefined || dgDiv === undefined) {
        return req
      }
      const dg = new DangerousGoods()
      dg.setClass(dgClass)
      dg.setDivision(dgDiv)
      req.setDangerousGoods(dg)
      req.setType(Requirement.Type.DANGEROUS_GOODS)
      break
    case Requirement.Type.TEMPERATURE_CONTROLLED:
      const tmpControlled = new TemperatureControlled()
      tmpControlled.setTemperature(value())
      req.setTemperatureControlled(tmpControlled)
      req.setType(Requirement.Type.TEMPERATURE_CONTROLLED)
      break
    case Requirement.Type.DRY_LOAD:
      const dryLoad = new DryLoad()
      dryLoad.setHumidity(value() ? +value() : 0)
      req.setDryLoad(dryLoad)
      req.setType(Requirement.Type.DRY_LOAD)
      break
    case Requirement.Type.HANGING_GARMENTS:
      const hangingGarments = new HangingGarments()
      hangingGarments.setQuantity(value() ? +value() : 0)
      req.setHangingGarments(hangingGarments)
      req.setType(Requirement.Type.HANGING_GARMENTS)
      break
    case Requirement.Type.BOX_TRAILER:
      req.setBoxTrailer(new BoxTrailer())
      req.setType(Requirement.Type.BOX_TRAILER)
      break
    case Requirement.Type.TAIL_LIFT_AT_LOADING:
      req.setTailLiftAtLoading(new TailLiftAtLoading())
      req.setType(Requirement.Type.TAIL_LIFT_AT_LOADING)
      break
    case Requirement.Type.TAIL_LIFT_AT_UNLOADING:
      req.setTailLiftAtUnloading(new TailLiftAtUnloading())
      req.setType(Requirement.Type.TAIL_LIFT_AT_UNLOADING)
      break
    case Requirement.Type.SENDER_NOTIFICATION:
      const senderNotification = new SenderNotification()
      senderNotification.setPhoneNumber(value())
      req.setSenderNotification(senderNotification)
      req.setType(Requirement.Type.SENDER_NOTIFICATION)
      break
    case Requirement.Type.RECEIVER_NOTIFICATION:
      const receiverNotification = new ReceiverNotification()
      receiverNotification.setPhoneNumber(value())
      req.setReceiverNotification(receiverNotification)
      req.setType(Requirement.Type.RECEIVER_NOTIFICATION)
      break
    case Requirement.Type.LOADING_NOTE:
      const loadingNote = new LoadingNote()
      loadingNote.setNote(value())
      req.setLoadingNote(loadingNote)
      req.setType(Requirement.Type.LOADING_NOTE)
      break
    case Requirement.Type.UNLOADING_NOTE:
      const unloadingNote = new UnloadingNote()
      unloadingNote.setNote(value())
      req.setUnloadingNote(unloadingNote)
      req.setType(Requirement.Type.UNLOADING_NOTE)
      break
    case Requirement.Type.EXTRA_LOADING:
      const extraLoading = new ExtraLoading()
      extraLoading.setNote(value())
      req.setExtraLoading(extraLoading)
      req.setType(Requirement.Type.EXTRA_LOADING)
      break
    case Requirement.Type.EXTRA_UNLOADING:
      const extraUnloading = new ExtraUnloading()
      extraUnloading.setNote(value())
      req.setExtraUnloading(extraUnloading)
      req.setType(Requirement.Type.EXTRA_UNLOADING)
      break
    case Requirement.Type.CUSTOMS_HANDLING_IMPORT:
      req.setCustomsHandlingImport(new CustomsHandlingImport())
      req.setType(Requirement.Type.CUSTOMS_HANDLING_IMPORT)
      break
    case Requirement.Type.CUSTOMS_HANDLING_EXPORT:
      req.setCustomsHandlingExport(new CustomsHandlingExport())
      req.setType(Requirement.Type.CUSTOMS_HANDLING_EXPORT)
      break
    case Requirement.Type.OTHER:
      const other = new OtherRequirement()
      other.setInfo(value())
      req.setOtherRequirement(other)
      req.setType(Requirement.Type.OTHER)
      break
  }
  return req
}

function bookingEquals(a?: Booking, b?: Booking): boolean {
  return (
    !!a &&
    !!b &&
    a.getDesiredTransportModesList() === b.getDesiredTransportModesList() &&
    requirementsEquals(a.getRequirementsList(), b.getRequirementsList()) //&&
    // TODO: Check add logic for submode items and check collis from there
    //colliEquals(a.getColliList(), b.getColliList())
  )
}

export function bookingEqualsNew(b: Booking): boolean {
  return bookingEquals(b, newBooking())
}

export function bookingEqualsTemplate(b?: Booking, bt?: BookingTemplate): boolean {
  if (!b || !bt) {
    return false
  }
  const bookingFromTemplate = newBooking()
  applyTemplate(bookingFromTemplate, bt)
  return bookingEquals(b, bookingFromTemplate)
}

function requirementsEquals(a: Array<Requirement>, b: Array<Requirement>): boolean {
  return a.every((v, i) => {
    return a[i] === b[i]
  })
}

export function getLatestGeolocation(booking: Booking): Geolocation.AsObject {
  // Primarily try to get the latest current location.
  const currentGeolocation = booking.getCurrentGeolocation()
  if (currentGeolocation) {
    return currentGeolocation.toObject()!
  }

  // Otherwise use the sender location.
  return booking.getSender()?.getGeolocation()?.toObject() ?? { lat: NaN, lng: NaN } // TODO: fix better solution
}

export function getBookingsWithGeolocation(
  bookings: Array<Booking>,
  geolocation: Geolocation.AsObject,
): Array<Booking> {
  return bookings.filter((b) => {
    const geo = getLatestGeolocation(b)
    return geo.lat === geolocation.lat && geo.lng === geolocation.lng
  })
}

// Booking helper functions
export function getBookingTotalWeight(booking: Booking): number {
  return (
    booking.getTransportSubmodeItemsList().reduce((a, s) => a + s.getTotalWeight(), 0) /
    weightFactor
  )
}

// Submode items helper functions
export function getSubmodeItemsTotalAmount(items: SubmodeItem[]): {
  [key: string]: number
} {
  return items
    .flatMap((s) => s.getColliList())
    .reduce(
      (acc, c) => {
        const v = acc[c.getColliType()] || 0
        acc[c.getColliType()] = v + c.getQuantity()
        return acc
      },
      {} as { [key: string]: number },
    )
}

export function getSubmodeItemsTotalDimension(items: SubmodeItem[]): number {
  return getColliListTotalDimensionAsCBM(items.flatMap((s) => s.getColliList()))
}

export function getSubmodeItemsTotalSpace(items: SubmodeItem[]): {
  [key in SubmodeItem.SpaceUnit]: number
} {
  return items.reduce(
    (acc, c) => {
      const v = acc[c.getTotalSpaceUnit()] || 0
      acc[c.getTotalSpaceUnit()] = v + c.getTotalSpace()
      return acc
    },
    {} as { [key in SubmodeItem.SpaceUnit]: number },
  )
}

export function getSubmodeItemsTotalWeight(items: SubmodeItem[]): number {
  return items.reduce((a, s) => a + s.getTotalWeight(), 0) / weightFactor
}

// Any change in this function should be reflected in the same function on the back-end
export function getSubmodeItemChargeableWeight(
  mode: TransportMode,
  submode: TransportSubmode,
  senderCountryID: string,
  receiverCountryID: string,
  space: number,
  unit: SubmodeItem.SpaceUnit,
): number {
  if (senderCountryID == '' || receiverCountryID == '') {
    return 0
  }

  let cw = 0

  // Exception - Road Sprinter CW should always be 1000kg
  if (mode === TransportMode.ROAD && submode == TransportSubmode.SPRINTER) {
    return 1000000 / chargeableWeightFactor
  }

  // Exception - Swedish bookings
  if (mode === TransportMode.ROAD && senderCountryID == 'se' && receiverCountryID == 'se') {
    const ldm = 1950000
    const ppl = 780000
    const cbm = 280000
    switch (unit) {
      case SubmodeItem.SpaceUnit.LDM:
        cw = space * ldm
        break
      case SubmodeItem.SpaceUnit.PPL:
        cw = space * ppl
        break
      case SubmodeItem.SpaceUnit.CBM:
        cw = space * cbm
        break
    }
    return cw / chargeableWeightFactor
  }

  // Exception - Swedish from and to Nordic bookings
  if (
    mode === TransportMode.ROAD &&
    (receiverCountryID == 'se' ||
      receiverCountryID == 'fi' ||
      receiverCountryID == 'no' ||
      receiverCountryID == 'dk') &&
    (senderCountryID == 'se' ||
      senderCountryID == 'fi' ||
      senderCountryID == 'no' ||
      senderCountryID == 'dk')
  ) {
    const ldm = 2000000
    const ppl = 800000
    const cbm = 350000
    switch (unit) {
      case SubmodeItem.SpaceUnit.LDM:
        cw = space * ldm
        break
      case SubmodeItem.SpaceUnit.PPL:
        cw = space * ppl
        break
      case SubmodeItem.SpaceUnit.CBM:
        cw = space * cbm
        break
    }
    return cw / chargeableWeightFactor
  }

  // Exception - Swedish to / from Turkey
  if (
    (senderCountryID == 'se' && receiverCountryID == 'tr' && mode === TransportMode.ROAD) ||
    (senderCountryID == 'tr' && receiverCountryID == 'se' && mode === TransportMode.ROAD)
  ) {
    const ldm = 1850000
    const ppl = 740000
    const cbm = 333000
    switch (unit) {
      case SubmodeItem.SpaceUnit.LDM:
        cw = space * ldm
        break
      case SubmodeItem.SpaceUnit.PPL:
        cw = space * ppl
        break
      case SubmodeItem.SpaceUnit.CBM:
        cw = space * cbm
        break
    }
    return cw / chargeableWeightFactor
  }

  // Normal Europe bookings
  switch (mode) {
    case TransportMode.AIR:
      const cbma = 167000
      cw = space * cbma
      return cw / chargeableWeightFactor
    case TransportMode.COURIER:
      const cbmc = 200000
      cw = space * cbmc
      return cw / chargeableWeightFactor
    case TransportMode.RAIL:
      const cbmr = 167000
      cw = space * cbmr
      return cw / chargeableWeightFactor
    case TransportMode.ROAD:
      const ldm = 1850000
      const ppl = 740000
      const cbm = 333000
      switch (unit) {
        case SubmodeItem.SpaceUnit.LDM:
          cw = space * ldm
          break
        case SubmodeItem.SpaceUnit.PPL:
          cw = space * ppl
          break
        case SubmodeItem.SpaceUnit.CBM:
          cw = space * cbm
          break
      }
      return cw / chargeableWeightFactor
    case TransportMode.SEA:
      const cbms = 1000000
      cw = space * cbms
      return cw / chargeableWeightFactor
  }

  return 0
}

// Collis helper functions

export function getColliListTotalAmount(colliList: Colli[]): { [key: string]: number } {
  return colliList.reduce(
    (acc, c) => {
      const v = acc[c.getColliType()] || 0
      acc[c.getColliType()] = v + c.getQuantity()
      return acc
    },
    {} as { [key: string]: number },
  )
}

export function getColliListTotalDimensionAsCBM(colliList: Colli[]): number {
  const totalVol = colliList.reduce((acc, c) => {
    const dim = c.getDimension()
    if (!dim) {
      return acc
    }

    return acc + (dim.getHeight() * dim.getLength() * dim.getWidth() * c.getQuantity()) / 1000000
  }, 0)

  return parseFloat(totalVol.toFixed(3))
}

export function getColliListTotalAreaAsLDM(colliList: Colli[]): number {
  const totalArea = colliList.reduce((acc, c) => {
    const dim = c.getDimension()
    if (!dim) {
      return acc
    }

    return acc + (dim.getLength() * dim.getWidth() * c.getQuantity()) / 10000 / 2.4
  }, 0)

  return parseFloat(totalArea.toFixed(3))
}

export function getColliListTotalSpace(colliList: Colli[]): {
  [key in Volume.Unit]: number
} {
  return colliList.reduce(
    (acc, c) => {
      const vol = c.getVolume()
      if (!vol || vol.getUnit() === Volume.Unit.UNKNOWN) {
        return acc
      }
      const v = acc[vol.getUnit()] || 0
      acc[vol.getUnit()] = v + vol.getValue()
      return acc
    },
    {} as { [key in Volume.Unit]: number },
  )
}

export function getColliListTotalWeight(colliList: Colli[]): number {
  return colliList.reduce((a, c) => a + c.getQuantity() * c.getWeight(), 0) / weightFactor
}

// Other

export function getHeight(colliList: Colli[]): number {
  return colliList.reduce((acc, c) => {
    const dim = c.getDimension()
    if (!dim) {
      return acc
    }
    return acc + dim.getHeight()
  }, 0)
}

export function getLength(colliList: Colli[]): number {
  return colliList.reduce((acc, c) => {
    const dim = c.getDimension()
    if (!dim) {
      return acc
    }
    return acc + dim.getLength()
  }, 0)
}

export function getWidth(colliList: Colli[]): number {
  return colliList.reduce((acc, c) => {
    const dim = c.getDimension()
    if (!dim) {
      return acc
    }
    return acc + dim.getWidth()
  }, 0)
}

export const dateFields: {
  [key in DateFilter.Field]: string
} = {
  [DateFilter.Field.UNKNOWN]: 'Missing filed',
  [DateFilter.Field.CREATEDAT]: 'Created at',
  [DateFilter.Field.UPDATEDAT]: 'Updated at',
  [DateFilter.Field.DPD]: 'Desired pickup date',
  [DateFilter.Field.EDT]: 'Estimated departure time',
  [DateFilter.Field.EAT]: 'Estimated arrival time',
  [DateFilter.Field.AAT]: 'Actual arrival time',
  [DateFilter.Field.ADT]: 'Actual departure time',
  [DateFilter.Field.DDD]: 'Desired delivery date',
  [DateFilter.Field.ET]: 'Exception time',
  [DateFilter.Field.DLPT]: 'Desired latest pickup time',
  [DateFilter.Field.DLDT]: 'Desired latest delivery time',
}

type filteredDateFieldsType = Exclude<DateFilter.Field, DateFilter.Field.UNKNOWN>

export const filteredDateFields: {
  [key in filteredDateFieldsType]: string
} = {
  [DateFilter.Field.CREATEDAT]: 'Created at',
  [DateFilter.Field.UPDATEDAT]: 'Updated at',
  [DateFilter.Field.DPD]: 'Desired pickup date',
  [DateFilter.Field.EDT]: 'Estimated departure time',
  [DateFilter.Field.EAT]: 'Estimated arrival time',
  [DateFilter.Field.AAT]: 'Actual arrival time',
  [DateFilter.Field.ADT]: 'Actual departure time',
  [DateFilter.Field.DDD]: 'Desired delivery date',
  [DateFilter.Field.ET]: 'Exception time',
  [DateFilter.Field.DLPT]: 'Desired latest pickup time',
  [DateFilter.Field.DLDT]: 'Desired latest delivery time',
}

export const dateFilterRelatives: {
  [key in DateFilter.Relative]: string
} = {
  [DateFilter.Relative.NOW]: 'Current time',
  [DateFilter.Relative.CURRENT_DATE]: 'Current date',
}

export const supplierPriceToShow = (booking: Booking, currentUser?: User) => {
  const roles = currentUser ? currentUser.getRolesList() : []

  if (!currentUser || !canSeeShipmentPrice(roles)) {
    return
  }

  if (
    canSeePrice(booking.getOwner(), currentUser) &&
    booking.getPrice() !== 0 &&
    booking.getPriceCurrency() !== ''
  ) {
    return `${booking.getPrice() / 100} ${booking.getPriceCurrency()}`
  }

  return 'Price as per agreement'
}

export const availableTransportSubmodeTypes: {
  [key in TransportMode]: TransportSubmode[]
} = {
  [TransportMode.ROAD]: [
    TransportSubmode.PART_LOAD,
    TransportSubmode.FULL_TRUCK_LOAD,
    TransportSubmode.SPRINTER,
  ],
  [TransportMode.SEA]: [TransportSubmode.LESS_CONTAINER_LOAD, TransportSubmode.FULL_CONTAINER_LOAD],
  [TransportMode.AIR]: [TransportSubmode.PART_LOAD],
  [TransportMode.COURIER]: [TransportSubmode.PART_LOAD],
  [TransportMode.RAIL]: [
    TransportSubmode.LESS_CONTAINER_LOAD,
    TransportSubmode.FULL_CONTAINER_LOAD,
  ],
  [TransportMode.UNKNOWN]: [TransportSubmode.UNSPECIFIED],
}

export const transportSubmodeTypeLabel: { [key in TransportSubmode]: string } = {
  [TransportSubmode.UNSPECIFIED]: 'Unspecified',
  [TransportSubmode.PART_LOAD]: 'Part Load',
  [TransportSubmode.FULL_TRUCK_LOAD]: 'Full Truck Load',
  [TransportSubmode.SPRINTER]: 'Sprinter',
  [TransportSubmode.LESS_CONTAINER_LOAD]: 'Less Container Load',
  [TransportSubmode.FULL_CONTAINER_LOAD]: 'Full Container Load',
}

export const transportSubmodeTypeLabelShortening: {
  [key in TransportSubmode]: string
} = {
  [TransportSubmode.UNSPECIFIED]: '',
  [TransportSubmode.PART_LOAD]: '',
  [TransportSubmode.FULL_TRUCK_LOAD]: '(FTL)',
  [TransportSubmode.SPRINTER]: '',
  [TransportSubmode.LESS_CONTAINER_LOAD]: '(LCL)',
  [TransportSubmode.FULL_CONTAINER_LOAD]: '(FCL)',
}

export const getTransportSubmodeTypeDescription = (
  mode: TransportMode,
  submode: TransportSubmode,
) => {
  if (submode === TransportSubmode.PART_LOAD) {
    switch (mode) {
      case TransportMode.AIR:
        return 'Sharing aircraft space for smaller quantities of goods'
      case TransportMode.COURIER:
        return 'Delivery service for parcels and small packages'
      case TransportMode.ROAD:
        return 'Sharing truck space for transporting smaller cargo loads'
    }
  }

  switch (submode) {
    case TransportSubmode.FULL_CONTAINER_LOAD:
      return 'Full shipping container for cargo of one customer'
    case TransportSubmode.LESS_CONTAINER_LOAD:
      return 'Sharing container space for smaller quantities of goods'
    case TransportSubmode.FULL_TRUCK_LOAD:
      return 'Full Truckload, Entire truck for transporting goods of one customer'
    case TransportSubmode.SPRINTER:
      return 'Commercial dedicated van transporting small to medium sized cargo'
    case TransportSubmode.UNSPECIFIED:
      return 'No sub-mode has been selected. By selecting one on this row you can set total weight and space for the whole booking'
  }
}

export const submodeSpaceUnitLabel: {
  [key in SubmodeItem.SpaceUnit]: string
} = {
  [SubmodeItem.SpaceUnit.CBM]: 'CBM',
  [SubmodeItem.SpaceUnit.LDM]: 'LDM',
  [SubmodeItem.SpaceUnit.PPL]: 'PPL',
}

export const getSubmodeTotalSpaceUnit = (mode: TransportMode, submode: TransportSubmode) => {
  if (mode === TransportMode.ROAD && submode === TransportSubmode.PART_LOAD) {
    return [SubmodeItem.SpaceUnit.LDM, SubmodeItem.SpaceUnit.PPL, SubmodeItem.SpaceUnit.CBM]
  }
  const submodeTotalSpaceUnits = {
    [TransportSubmode.PART_LOAD]: [SubmodeItem.SpaceUnit.CBM],
    [TransportSubmode.FULL_TRUCK_LOAD]: [SubmodeItem.SpaceUnit.LDM],
    [TransportSubmode.SPRINTER]: [SubmodeItem.SpaceUnit.LDM],
    [TransportSubmode.LESS_CONTAINER_LOAD]: [SubmodeItem.SpaceUnit.CBM],
    [TransportSubmode.FULL_CONTAINER_LOAD]: [SubmodeItem.SpaceUnit.CBM],
    [TransportSubmode.UNSPECIFIED]: [SubmodeItem.SpaceUnit.CBM],
  }
  return submodeTotalSpaceUnits[submode]
}

export const fullContainerLoadTypes: {
  [key in TransportMode.SEA | TransportMode.RAIL]: SubmodeItem.ContainerType[]
} = {
  [TransportMode.SEA]: [
    SubmodeItem.ContainerType.DV20,
    SubmodeItem.ContainerType.DV40,
    SubmodeItem.ContainerType.HC40,
    SubmodeItem.ContainerType.RF20,
    SubmodeItem.ContainerType.RF40,
  ],
  [TransportMode.RAIL]: [
    SubmodeItem.ContainerType.DV40,
    SubmodeItem.ContainerType.HC40,
    SubmodeItem.ContainerType.RF40,
  ],
}

export const submodeTotalSpaceUnitLabel: { [key in SubmodeItem.ContainerType]: string } = {
  [SubmodeItem.ContainerType.DV20]: '20ft Dry Van',
  [SubmodeItem.ContainerType.DV40]: '40ft Dry Van',
  [SubmodeItem.ContainerType.HC40]: '40ft High Cube',
  [SubmodeItem.ContainerType.RF20]: '20ft Reefer',
  [SubmodeItem.ContainerType.RF40]: '40ft Reefer',
  [SubmodeItem.ContainerType.UNDEFINED]: 'Unknown',
}

export const transportModeIsContainerBased = (mode: TransportMode) => {
  return mode === TransportMode.SEA || mode === TransportMode.RAIL
}

export const transportModeHasPartLoad = (mode: TransportMode) => {
  return mode === TransportMode.AIR || mode === TransportMode.COURIER || mode === TransportMode.ROAD
}

export type SubModeItemColType =
  | 'total_weight'
  | 'space'
  | 'space_unit'
  | 'chargeable_weight'
  | 'container_type'
export type SubModeItemCol = { required: boolean; enabled: boolean }
export type SubmodeItemColReqs = { [key in SubModeItemColType]: SubModeItemCol }

export const submodeColReqs: { [key in TransportSubmode]: SubmodeItemColReqs } = {
  [TransportSubmode.UNSPECIFIED]: {
    total_weight: { required: true, enabled: true },
    space: { required: true, enabled: true },
    space_unit: { required: true, enabled: true },
    chargeable_weight: { required: false, enabled: false },
    container_type: { required: false, enabled: false },
  },
  [TransportSubmode.PART_LOAD]: {
    total_weight: { required: true, enabled: true },
    space: { required: true, enabled: true },
    space_unit: { required: true, enabled: true },
    chargeable_weight: { required: false, enabled: false },
    container_type: { required: false, enabled: false },
  },
  [TransportSubmode.FULL_TRUCK_LOAD]: {
    total_weight: { required: true, enabled: true },
    space: { required: true, enabled: false },
    space_unit: { required: true, enabled: false },
    chargeable_weight: { required: false, enabled: false },
    container_type: { required: false, enabled: false },
  },
  [TransportSubmode.SPRINTER]: {
    total_weight: { required: true, enabled: true },
    space: { required: true, enabled: false },
    space_unit: { required: true, enabled: false },
    chargeable_weight: { required: false, enabled: false },
    container_type: { required: false, enabled: false },
  },
  [TransportSubmode.LESS_CONTAINER_LOAD]: {
    total_weight: { required: true, enabled: true },
    space: { required: true, enabled: true },
    space_unit: { required: true, enabled: true },
    chargeable_weight: { required: false, enabled: false },
    container_type: { required: false, enabled: false },
  },
  [TransportSubmode.FULL_CONTAINER_LOAD]: {
    total_weight: { required: true, enabled: true },
    space: { required: true, enabled: false },
    space_unit: { required: true, enabled: false },
    chargeable_weight: { required: false, enabled: false },
    container_type: { required: true, enabled: true },
  },
}

export const getPreSetTotalSpace = (
  mode: TransportMode,
  submode: TransportSubmode,
  containerType: SubmodeItem.ContainerType,
) => {
  if (mode === TransportMode.SEA && submode === TransportSubmode.FULL_CONTAINER_LOAD) {
    switch (containerType) {
      case SubmodeItem.ContainerType.DV20:
        return { space: 33, unit: SubmodeItem.SpaceUnit.CBM }
      case SubmodeItem.ContainerType.DV40:
        return { space: 66, unit: SubmodeItem.SpaceUnit.CBM }
      case SubmodeItem.ContainerType.HC40:
        return { space: 76, unit: SubmodeItem.SpaceUnit.CBM }
      case SubmodeItem.ContainerType.RF20:
        return { space: 27, unit: SubmodeItem.SpaceUnit.CBM }
      case SubmodeItem.ContainerType.RF40:
        return { space: 58, unit: SubmodeItem.SpaceUnit.CBM }
    }
  }
  if (mode === TransportMode.RAIL && submode === TransportSubmode.FULL_CONTAINER_LOAD) {
    switch (containerType) {
      case SubmodeItem.ContainerType.DV40:
        return { space: 66, unit: SubmodeItem.SpaceUnit.CBM }
      case SubmodeItem.ContainerType.HC40:
        return { space: 76, unit: SubmodeItem.SpaceUnit.CBM }
      case SubmodeItem.ContainerType.RF40:
        return { space: 76, unit: SubmodeItem.SpaceUnit.CBM }
    }
  }
  if (mode === TransportMode.ROAD) {
    switch (submode) {
      case TransportSubmode.FULL_TRUCK_LOAD:
        return { space: 13.6, unit: SubmodeItem.SpaceUnit.LDM }
      case TransportSubmode.SPRINTER:
        return { space: 3.2, unit: SubmodeItem.SpaceUnit.LDM }
      case TransportSubmode.PART_LOAD:
        return { space: 0, unit: SubmodeItem.SpaceUnit.LDM } // Want default unit but not space
    }
  }

  return false
}

export const submodeHasCalculatedCollis = (mode: TransportMode, submode?: TransportSubmode) => {
  // AIR and COURIER are special cases, this func should return true to show colli table expanded by default
  if (mode === TransportMode.AIR || mode === TransportMode.COURIER) {
    return true
  }
  return mode !== TransportMode.ROAD && submode === TransportSubmode.PART_LOAD
}

export const canAddMultipleSubmodeItems = (submode?: TransportSubmode) => {
  return submode === TransportSubmode.FULL_CONTAINER_LOAD
}

export const canSeeContainerNumber = (submode: TransportSubmode) => {
  return submode === TransportSubmode.FULL_CONTAINER_LOAD
}

export const canSeeTruckPlate = (mode: TransportMode) => {
  return mode === TransportMode.ROAD
}

export const canSeeAirwayBillNumber = (mode: TransportMode) => {
  return mode === TransportMode.AIR
}

export const unitReferenceTitle: { [key in TransportMode]: string } = {
  [TransportMode.AIR]: 'Airway Bill Number',
  [TransportMode.ROAD]: 'Truck Plate',
  [TransportMode.RAIL]: 'Container Number',
  [TransportMode.SEA]: 'Container Number',
  [TransportMode.COURIER]: '',
  [TransportMode.UNKNOWN]: '',
}
