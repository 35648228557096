import { CreateSurchargeType, ISurcharge } from 'shared/interface/surcharge.interface'

import { Surcharge } from 'proto/contract/v1/contract_pb'

import { dateStringToTimeStamp, timestampToDateTime } from 'shared/helpers/datetime.helper'

const toSurchargeProto = (surcharge: ISurcharge | CreateSurchargeType): Surcharge => {
  const proto = new Surcharge()
  if ('surchargeId' in surcharge) {
    proto.setSurchargeId(surcharge.surchargeId)
  }
  if ('createdAt' in surcharge && surcharge.createdAt) {
    proto.setCreatedAt(dateStringToTimeStamp(surcharge.createdAt))
  }
  proto.setSurchargeType(surcharge.surchargeType)
  proto.setRate(surcharge.rate)
  proto.setRateType(surcharge.rateType)
  proto.setCurrency(surcharge.currency)
  proto.setOrganizationId(surcharge.organizationId)
  proto.setCategory(surcharge.category)
  proto.setIsActive(surcharge.isActive)
  return proto
}

const fromSurchargeProto = (surcharge: Surcharge): ISurcharge => {
  return {
    surchargeId: surcharge.getSurchargeId(),
    organizationId: surcharge.getOrganizationId(),
    surchargeType: surcharge.getSurchargeType(),
    rate: surcharge.getRate(),
    rateType: surcharge.getRateType(),
    currency: surcharge.getCurrency(),
    createdAt: timestampToDateTime(surcharge.getCreatedAt()),
    category: surcharge.getCategory(),
    isActive: surcharge.getIsActive(),
  }
}

export { fromSurchargeProto, toSurchargeProto }
