import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { CloseOutlined, MenuOutlined } from '@ant-design/icons'
import { Button, Menu } from 'antd'
import { LDFlagSet, useFlags } from 'launchdarkly-react-client-sdk'

import { User } from 'proto/iam/v1/user_pb'

import { LinkComponent } from 'components/Links/Link'

import logo from '../assets/images/logo-intereast-red.png'
import { MenuItem } from './SiderMenuItems'
import {
  AddressesMenuItem,
  AdminMenuItem,
  BookingsMenuItem,
  ContractsMenuItem,
  DraftsMenuItem,
  FinanceMenuItem,
  HomeMenuItem,
  InvoicesMenuItem,
  OrdersMenuItem,
  PrivacyMenuItem,
  ProfileMenuItem,
  QuotesMenuItem,
  ReportsMenuItem,
  ShipmentsMenuItem,
  SignOutMenuItem,
  keyFromPath,
} from './SiderMenuItems'
import './navbar.css'

export interface Props {
  onLogout: () => void
  readonly roles: Array<User.Role>
}

export const Navbar = ({ onLogout, roles }: Props) => {
  const flags = useFlags()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const location = useLocation()
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <div className="navbar">
      <Button
        type="default"
        onClick={toggleMenu}
        className="navbar__hamburger-menu"
        icon={<MenuOutlined className="navbar__hamburger-menu-icon" />}
      />
      <div className={`navbar__menu ${isMenuOpen ? 'navbar__menu--open' : ''}`}>
        <Button
          type="default"
          onClick={toggleMenu}
          className="navbar__close-button"
          icon={<CloseOutlined />}
        />
        <Menu
          triggerSubMenuAction={'click'}
          defaultSelectedKeys={['/']}
          selectedKeys={[keyFromPath(location.pathname)]}
          mode="inline"
          items={menuItems(roles, onLogout, flags)}
          onSelect={toggleMenu}
          className="navbar__menu-items"
        />
      </div>
      <div className="navbar-logo">
        <LinkComponent to="/">
          <img src={logo} style={{ height: 28 }} alt="logo" />
        </LinkComponent>
      </div>
    </div>
  )
}

const menuItems = (
  roles: User.Role[],
  onLogout: () => void,
  featureFlags?: LDFlagSet,
): MenuItem[] => {
  const items: MenuItem[] = [
    HomeMenuItem(),
    OrdersMenuItem(roles, featureFlags),
    BookingsMenuItem(),
    DraftsMenuItem(),
    QuotesMenuItem(featureFlags),
    ContractsMenuItem(roles, featureFlags),
    ShipmentsMenuItem(roles),
    InvoicesMenuItem(roles),
    FinanceMenuItem(roles),
    AddressesMenuItem(),
    ReportsMenuItem(roles, featureFlags),
    AdminMenuItem(roles),
    ProfileMenuItem(),
    PrivacyMenuItem(),
    { type: 'divider' },
    SignOutMenuItem(onLogout),
  ]

  return items
}
