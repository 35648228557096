import * as React from 'react'

import { Layout } from 'antd'

import { dateTime } from 'shared/helpers/datetime.helper'

interface Props {
  style?: React.CSSProperties
}

export const Footer: React.FC<Props> = ({ style }) => (
  <Layout.Footer
    className="Footer"
    style={{
      textAlign: 'center',
      background: 'transparent',
      fontFamily: 'TT-Norms-Pro-Regular',
      padding: 0,
      ...style,
    }}
  >
    InterEast Transport AB © {dateTime().year} ({window.env.VERSION})
    <br />
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <a
          href="https://www.iubenda.com/privacy-policy/47235440"
          className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iub-legal-only iubenda-noiframe "
          title="Privacy Policy "
        >
          Privacy Policy
        </a>
      </div>

      <div style={{ marginLeft: 10 }}>
        <a
          href="https://www.iubenda.com/privacy-policy/47235440/cookie-policy"
          className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
          title="Cookie Policy "
        >
          Cookie Policy
        </a>
      </div>
      <div style={{ marginLeft: 10 }}>
        <a href="#" className="iubenda-cs-preferences-link">
          Your Privacy Choices
        </a>
      </div>
    </div>
  </Layout.Footer>
)
