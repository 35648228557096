import * as grpcWeb from 'grpc-web'
import { call, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects'

import * as commonQuery from 'proto/common/query_pb'
import { UserServicePromiseClient } from 'proto/iam/v1/user_grpc_web_pb'
import * as userv1 from 'proto/iam/v1/user_pb'

import { SIGNUP_ERR, SIGNUP_RESP } from 'store/auth/actions'
import { Actions as AuthActions } from 'store/auth/actions'
import { getSignedUpCredential } from 'store/auth/reducer'
import {
  Actions,
  CREATE_REQ,
  EDIT_REQ,
  GET_REQ,
  LIST_PUBLIC_USERS_REQ,
  LIST_USERS_REQ,
  UPLOAD_PROFILE_PICTURE_REQ,
} from 'store/iam/user/actions'
import { GET_STORY_USERS_REQ, Actions as StoryActions } from 'store/story/actions'

import { authMetadata } from 'helpers/auth'

import { AuthSignup } from 'types/signup'

export function* listUsers(
  client: UserServicePromiseClient,
  action: ReturnType<typeof Actions.listUsersReq>,
) {
  try {
    const { page } = action.payload
    const req = new userv1.ListUsersRequest()

    if (page.pagination.limit !== 0 || page.pagination.skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(page.pagination.limit)
      pagination.setSkip(page.pagination.skip)
      req.setPagination(pagination)
    }
    if (page.filter) {
      const filter = new userv1.UserListFilter()
      if (page.filter.organizationID && page.filter.organizationID.length > 0) {
        filter.setOrganizationIdList(page.filter.organizationID)
      }
      if (page.filter.usergroupID && page.filter.usergroupID.length > 0) {
        filter.setUserGroupIdList(page.filter.usergroupID)
      }
      if (page.filter.excludeDisabled) {
        filter.setExcludeDisabled(page.filter.excludeDisabled)
      }
      req.setFilter(filter)
    }
    if (page.sorting) {
      const sort = new userv1.UserListSorting()
      sort.setField(page.sorting.getField())
      sort.setOrdering(page.sorting.getOrdering())
      req.setSorting(sort)
    }

    const resp: userv1.ListUsersResponse = yield call(
      [client, client.listUsers],
      req,
      authMetadata(),
    )
    yield put(Actions.listUsersResp(resp.getTotalCount(), resp.getUsersList(), page)) // Fix: Pass the 'page' argument
  } catch (err: any) {
    yield put(Actions.listUsersErr(err))
  }
}

export function* listPublicUsers(
  client: UserServicePromiseClient,
  action: ReturnType<typeof Actions.listPublicUsersReq>,
) {
  try {
    const { limit, skip } = action.payload
    const req = new userv1.ListPublicUsersRequest()

    if (limit !== 0 || skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(limit)
      pagination.setSkip(skip)
      req.setPagination(pagination)
    }

    const resp: userv1.ListPublicUsersResponse = yield call(
      [client, client.listPublicUsers],
      req,
      authMetadata(),
    )
    yield put(Actions.listPublicUsersResp(resp.getTotalCount(), resp.getUsersList()))
  } catch (err: any) {
    yield put(Actions.listPublicUsersErr(err))
  }
}

export function* listStoryUsers(
  client: UserServicePromiseClient,
  action: ReturnType<typeof StoryActions.getStoryUsersReq>,
) {
  const { usersIds } = action.payload
  if (usersIds.length > 0) {
    try {
      const req = new userv1.ListUsersWithImagesRequest()
      req.setUserIdsList(usersIds)
      const resp: userv1.ListUsersWithImagesResponse = yield call(
        [client, client.listUsersWithImages],
        req,
        authMetadata(),
      )
      const users = resp.getUsersList()
      const newUsers = Object.fromEntries(users.map((user) => [user.getUserId(), user]))
      yield put(StoryActions.getStoryUsersResp(newUsers))
    } catch (err) {
      yield put(StoryActions.getStoryUsersResp({}))
    }
  } else {
    yield put(StoryActions.getStoryUsersErr())
  }
}

export function* get(
  client: UserServicePromiseClient,
  action: ReturnType<typeof Actions.getUserReq>,
) {
  try {
    const { id } = action.payload
    const req = new userv1.GetUserRequest()
    req.setUserId(id)
    const resp: userv1.GetUserResponse = yield call([client, client.getUser], req, authMetadata())
    yield put(Actions.getUserResp(resp.getUser()))
  } catch (err: any) {
    if (
      err.code &&
      (err.code === grpcWeb.StatusCode.NOT_FOUND || grpcWeb.StatusCode.PERMISSION_DENIED)
    ) {
      yield put(Actions.getUserResp(undefined))
    } else {
      yield put(Actions.getUserErr(err))
    }
  }
}

export function* create(
  client: UserServicePromiseClient,
  action: ReturnType<typeof Actions.createUserReq>,
) {
  try {
    const { user } = action.payload
    yield put(AuthActions.signupReq(user.getEmail()))
    const { signupErr } = yield race({
      signupResp: take(SIGNUP_RESP),
      signupErr: take(SIGNUP_ERR),
    })
    if (signupErr) {
      throw new Error('error while signup user')
    }

    const newCredentials: AuthSignup = yield select(getSignedUpCredential)
    // Check if the new credentials are correct.
    if (!newCredentials || newCredentials.email.toLowerCase() !== user.getEmail().toLowerCase()) {
      throw new Error('wrong email found')
    }
    user.setUserId(newCredentials.id)
    const req = new userv1.CreateUserRequest()
    req.setUser(user)
    const resp: userv1.CreateUserResponse = yield call(
      [client, client.createUser],
      req,
      authMetadata(),
    )
    const newUser = resp.getUser()
    if (!newUser) {
      throw new Error('missing user')
    }
    yield put(Actions.createUserResp(newUser))
  } catch (err: any) {
    yield put(Actions.createUserErr(err))
  }
}

export function* edit(
  client: UserServicePromiseClient,
  action: ReturnType<typeof Actions.editUserReq>,
) {
  try {
    const { user } = action.payload
    const req = new userv1.EditUserRequest()
    req.setUser(user)
    const resp: userv1.EditUserResponse = yield call([client, client.editUser], req, authMetadata())
    const newUser = resp.getUser()
    if (!newUser) {
      throw new Error('missing user')
    }
    yield put(Actions.editUserResp(newUser))
  } catch (err: any) {
    yield put(Actions.editUserErr(err))
  }
}

export function* uploadProfilePicture(
  client: UserServicePromiseClient,
  action: ReturnType<typeof Actions.uploadProfilePictureReq>,
) {
  try {
    const { user, file, format } = action.payload
    const userImage = new userv1.UserImage()

    userImage.setImg(file)
    const req = new userv1.UploadProfilePictureRequest()
    req.setUserImg(userImage)
    req.setFormat(format)
    yield call([client, client.uploadProfilePicture], req, authMetadata())

    const newUser = user
    newUser.setImg(userImage.getImg())
    yield put(Actions.setCurrentUser(newUser))
  } catch (err: any) {
    yield put(Actions.uploadProfilePictureErr(err))
  }
}

export default function* sagas() {
  const client = new UserServicePromiseClient('')

  yield takeLatest(LIST_USERS_REQ, listUsers, client)
  yield takeLatest(LIST_PUBLIC_USERS_REQ, listPublicUsers, client)
  yield takeLatest(GET_STORY_USERS_REQ, listStoryUsers, client)
  yield takeLatest(GET_REQ, get, client)
  yield takeEvery(CREATE_REQ, create, client)
  yield takeEvery(EDIT_REQ, edit, client)
  yield takeEvery(UPLOAD_PROFILE_PICTURE_REQ, uploadProfilePicture, client)
}
