import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Form, Select } from 'antd'
import * as lib from 'country-data'
import parsePhoneNumberFromString from 'libphonenumber-js'

import * as branchSelectors from '../store/iam/branch/reducer'
import * as organizationSelectors from '../store/iam/organization/reducer'
import { RootState } from '../store/reducer'

import { TextInput } from './Inputs'

interface Props {
  defaultPhoneValue?: string
  defaultCode: string
  required?: boolean
  phoneName?: string
  countryCodeName?: string
  layout?: any
  label?: string
  getValidationError?: (messages: string[]) => void
  onChange?: (phoneNumber: string) => void
  style?: React.CSSProperties
}

export const PhoneInput = ({
  defaultCode,
  defaultPhoneValue,
  phoneName = 'cp',
  required = true,
  countryCodeName = 'cpcn',
  label = 'Phone',
  layout,
  getValidationError,
  onChange,
  style,
}: Props) => {
  const branchCountryCode = useSelector((state: RootState) =>
    branchSelectors.getCurrentBranchCountryCode(state),
  )

  const currentOrg = useSelector((state: RootState) => organizationSelectors.getCurrentOrg(state))
  const orgCountryCode = currentOrg?.getAddress()?.getCountryId().toUpperCase()

  const branchPhoneCountryCode = branchCountryCode
    ? (lib.countries[branchCountryCode]?.countryCallingCodes[0] ?? '')
    : ''

  const organizationCountryCode = orgCountryCode
    ? (lib.countries[orgCountryCode]?.countryCallingCodes[0] ?? '')
    : ''

  const [countryCode, setCountryCode] = useState(
    defaultCode || organizationCountryCode || branchPhoneCountryCode,
  )
  const validatePhone = async (rule: any, value: any) => {
    const messages: string[] = []
    const phoneNumber = parsePhoneNumberFromString(countryCode + value)
    if (required && value.length === 0) {
      messages.push('Phone number is required')
    }
    if (!phoneNumber) {
      const errorMessage = 'Enter a valid phone number'
      messages.push(errorMessage)
      getValidationError && getValidationError(messages)
      throw new Error(errorMessage) // Error needs to be thrown for antd to show error message
    }
    getValidationError && getValidationError(messages)
    onChange && onChange(phoneNumber.format('E.164'))
  }

  const codesWithFlags = lib.callingCodes.all.map((c) => {
    return {
      countryCallingCode: c,
      flag:
        lib.countries.all.find((country) => country.countryCallingCodes.find((code) => code === c))
          ?.emoji ?? '',
    }
  })

  const sortedCodes = codesWithFlags.sort((a, b) => {
    // Sorts the codes numerically.
    const aNameTrimmed = +a.countryCallingCode.replace('+', '').replace(/ /g, '')
    const bNameTrimmed = +b.countryCallingCode.replace('+', '').replace(/ /g, '')
    return aNameTrimmed > bNameTrimmed ? 1 : -1
  })

  const CountryCode = (
    <Form.Item
      name={countryCodeName}
      initialValue={defaultCode || organizationCountryCode || branchPhoneCountryCode}
      noStyle
    >
      <Select
        showSearch
        style={{ maxWidth: '130px', minWidth: '93px' }}
        onChange={(value: string) => {
          setCountryCode(value)
        }}
        value={countryCode}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        {sortedCodes.map((r) => (
          <Select.Option key={r.countryCallingCode} value={r.countryCallingCode}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ marginRight: '8px' }}>{r.countryCallingCode}</div>
              <div>{r.flag}</div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )

  return (
    <Form.Item
      name={phoneName}
      label={label}
      {...layout}
      rules={[
        { required, message: 'Phone number is required' },
        { required, validator: validatePhone },
      ]}
      style={style}
    >
      <TextInput
        size="xl"
        defaultValue={
          defaultPhoneValue
            ? (parsePhoneNumberFromString(defaultPhoneValue)?.nationalNumber as string)
            : ''
        }
        addonBefore={CountryCode}
        placeholder="0707-717171"
      />
    </Form.Item>
  )
}
