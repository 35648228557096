import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as bookingv1 from 'proto/booking/v1/booking_pb'
import * as commonGeolocation from 'proto/common/geolocation_pb'
import * as organizationv1 from 'proto/iam/v1/organization_pb'
import * as userv1 from 'proto/iam/v1/user_pb'
import * as orderv1 from 'proto/order/v1/order_pb'

import { Actions as BookingActions } from 'store/booking/booking/actions'
import * as bookingSelectors from 'store/booking/booking/reducer'
import * as organizationSelectors from 'store/iam/organization/reducer'
import * as userSelectors from 'store/iam/user/reducer'
import { Actions as OrderActions } from 'store/order/order/actions'
import * as orderSelectors from 'store/order/order/reducer'
import { RootState } from 'store/reducer'
import { Actions as PageActions } from 'store/ui/page/actions'
import * as pageSelectors from 'store/ui/page/reducer'

import { BottomContentLayout } from 'components/Layouts/BottomContentLayout'
import {
  CustomMarker,
  CustomMarkerClusterer,
  Map,
  MapWrapper,
  Popup,
  getMarkerIconForTransportModesList,
} from 'components/Map'

import { getBookingsWithGeolocation, getLatestGeolocation } from 'helpers/booking'
import { canCreateBooking, canCreateOrder, shouldCreateOrder } from 'helpers/rolePermissions'

import * as bookingTypes from 'types/booking'
import * as orderTypes from 'types/order'

import { BookingsCard, OrdersCard } from './Cards'
import './Cards/style.css'

export const Home = () => {
  const dispatch = useDispatch()

  // Props.
  const user = useSelector<RootState, userv1.User | undefined>((state) =>
    userSelectors.getCurrentUser(state),
  )

  const [userFilters] = useState<Array<userv1.UserFilter>>(
    user ? user.getFiltersList().filter((f) => !f.getDisabled()) : [],
  )

  const currentUserRoles = useSelector<RootState, userv1.User.Role[]>((state) =>
    userSelectors.getAllRoles(state),
  )
  const currentUserOrganization = useSelector<RootState, organizationv1.Organization | undefined>(
    (state) => organizationSelectors.getCurrentOrg(state),
  )
  const orders = useSelector<RootState, orderv1.Order[]>((state) =>
    orderSelectors.getPageItems(state, pageSelectors.GetHomeOrderPage(state)),
  )
  const orderFetching = useSelector<RootState, boolean>((state) =>
    orderSelectors.getIsFetching(state),
  )
  const orderErr = useSelector<RootState, Error | undefined>((state) =>
    orderSelectors.getErr(state),
  )
  const bookings = useSelector<RootState, bookingv1.Booking[]>((state) =>
    bookingSelectors.getPageItems(state, pageSelectors.GetHomeBookingPage(state)),
  )
  const bookingFetching = useSelector<RootState, boolean>((state) =>
    bookingSelectors.getIsFetching(state),
  )
  const bookingErr = useSelector<RootState, Error | undefined>((state) =>
    bookingSelectors.getErr(state),
  )

  // State.
  const [selectedBooking, setSelectedBooking] = useState<bookingv1.Booking | undefined>()
  const [hoveredBooking, setHoveredBooking] = useState<bookingv1.Booking | undefined>()
  const [mapPopupExpanded, setMapPopupExpanded] = useState<boolean>(false)
  const [activeBookings, setActiveBookings] = useState<bookingv1.Booking[]>([])
  const [bookingsSharingGeolocation, setBookingsSharingGeolocation] = useState<bookingv1.Booking[]>(
    [],
  )
  const [mapCenterGeolocation, setMapCenterGeolocation] =
    useState<commonGeolocation.Geolocation.AsObject>()
  const [mapZoom, setMapZoom] = useState<number>(2.8)

  // Effect.
  // ComponentDidMount.
  useEffect(() => {
    document.title = 'Home'
    const orderPage = orderTypes.getDefaultOrderPage()
    const bookingPage = bookingTypes.getDefaultBookingPage()
    orderPage.filter = {
      status: [
        orderv1.Order.Status.SUBMITTED,
        orderv1.Order.Status.CHECKED,
        orderv1.Order.Status.APPROVED,
      ],
      type: [orderv1.OrderType.RETURN, orderv1.OrderType.PURCHASE],
    }
    bookingPage.filter = {
      status: [bookingv1.Booking.Status.ACCEPTED, bookingv1.Booking.Status.IN_TRANSIT],
    }
    // Set default user filter if nothing was selected before
    bookingPage.filterID = userFilters.length > 0 ? userFilters[0].getFilterId() : 0

    dispatch(PageActions.setHomeOrderPage(orderPage, bookingPage))
    dispatch(OrderActions.listOrdersReq(orderPage))
    dispatch(BookingActions.listBookingsReq(bookingPage, false, true))
  }, [])

  // bookings list updated.
  useEffect(() => {
    const newActiveBookings = bookings.filter((b) => !!getLatestGeolocation(b))
    const storedActiveBookings = activeBookings
    const newBookingIds = newActiveBookings.map((b) => b.getBookingId())
    const storedBookingIds = storedActiveBookings.map((b) => b.getBookingId())
    const bookingsMatch =
      newBookingIds.length === storedBookingIds.length &&
      newActiveBookings.every((nb) => storedActiveBookings.some((n) => n === nb))
    !bookingsMatch && setActiveBookings(newActiveBookings)
  }, [bookings.length])

  const isBookingSelected = (booking: bookingv1.Booking) => {
    if (
      booking.getBookingId() === hoveredBooking?.getBookingId() ||
      (!hoveredBooking && booking.getBookingId() === selectedBooking?.getBookingId())
    ) {
      return true
    }
    return false
  }

  const handleMapPopupBookingChange = (booking: bookingv1.Booking) => {
    let newSelectedBooking: bookingv1.Booking | undefined = booking
    let bookingsSharingGeolocation: Array<bookingv1.Booking> = []
    let newMapPopupExpanded: boolean = mapPopupExpanded
    // Deselect booking if selected booking is clicked
    if (booking && selectedBooking && booking.getBookingId() === selectedBooking.getBookingId()) {
      newSelectedBooking = undefined
      newMapPopupExpanded = false
    } else {
      newMapPopupExpanded = true
    }

    // Get all bookings that share the same latest geolocation as the one selected
    if (newSelectedBooking) {
      const selectedBookingGeolocation = getLatestGeolocation(newSelectedBooking)
      bookingsSharingGeolocation = activeBookings
        .filter((b) => {
          const currentBookingGeolocation = getLatestGeolocation(b)

          return (
            currentBookingGeolocation.lat === selectedBookingGeolocation.lat &&
            currentBookingGeolocation.lng === selectedBookingGeolocation.lng
          )
        })
        .reverse() // reverse it to have the right order for the pagination
    }
    setSelectedBooking(newSelectedBooking)
    setMapPopupExpanded(newMapPopupExpanded)
    setBookingsSharingGeolocation(bookingsSharingGeolocation)
  }

  const handlePreviousPopupBooking = () => {
    const currentIndex = bookingsSharingGeolocation.findIndex(
      (b) => b.getBookingId() === selectedBooking?.getBookingId(),
    )

    if (currentIndex > 0) {
      setSelectedBooking(bookingsSharingGeolocation[currentIndex - 1])
    }
  }

  const handleNextPopupBooking = () => {
    const currentIndex = bookingsSharingGeolocation.findIndex(
      (b) => b.getBookingId() === selectedBooking?.getBookingId(),
    )

    if (currentIndex < bookingsSharingGeolocation.length - 1) {
      setSelectedBooking(bookingsSharingGeolocation[currentIndex + 1])
    }
  }

  const handleBookingsCardRowClick = (bookingRef: string) => {
    const booking = activeBookings.find((b) => b.getBookingRef() === bookingRef)
    if (!booking) {
      return
    }
    handleMapPopupBookingChange(booking)

    // If a new booking is selected
    if (!selectedBooking || selectedBooking.getBookingRef() !== bookingRef) {
      setMapCenterGeolocation(getLatestGeolocation(booking))
      setMapZoom(mapZoom === 7 ? 7.0001 : 7) // This slight "change" in zoom value makes sure the map re-zooms
    }
  }

  return (
    <BottomContentLayout shadow={true}>
      <div className="home__container">
        <MapWrapper>
          <Map
            centerGeolocation={mapCenterGeolocation}
            zoom={mapZoom}
            containerStyle={{
              borderRadius: '5px',
            }}
          >
            <CustomMarkerClusterer
              onClick={() => {
                setSelectedBooking(undefined)
                setMapPopupExpanded(false)
              }}
            >
              {(clusterer) => (
                <>
                  {activeBookings.map((b) => {
                    return (
                      <CustomMarker
                        key={b.getBookingId()}
                        geolocation={getLatestGeolocation(b)}
                        icon={
                          getMarkerIconForTransportModesList(
                            [b.getNominatedTransportMode()],
                            isBookingSelected(b),
                            getBookingsWithGeolocation(activeBookings, getLatestGeolocation(b))
                              .length,
                          )!
                        }
                        onClick={() => handleMapPopupBookingChange(b)}
                        zIndex={b.getBookingId() === selectedBooking?.getBookingId() ? 2 : 1}
                        clusterer={clusterer}
                        onMouseOver={() => setHoveredBooking(b)}
                        onMouseOut={() => setHoveredBooking(undefined)}
                      />
                    )
                  })}
                </>
              )}
            </CustomMarkerClusterer>
            <Popup
              booking={selectedBooking}
              expanded={mapPopupExpanded}
              onExpandToggle={() => setMapPopupExpanded(!mapPopupExpanded)}
              paginationCurrentPage={
                bookingsSharingGeolocation.findIndex(
                  (b) => b.getBookingId() === selectedBooking?.getBookingId(),
                ) + 1
              }
              paginationTotal={bookingsSharingGeolocation.length}
              onPreviousBooking={() => handlePreviousPopupBooking()}
              onNextBooking={() => handleNextPopupBooking()}
            />
          </Map>
        </MapWrapper>

        <div className="home__two-columns">
          <OrdersCard
            orders={orders}
            showCreateButton={
              canCreateOrder(currentUserRoles) &&
              shouldCreateOrder(currentUserRoles, currentUserOrganization)
            }
            fetching={orderFetching}
            error={orderErr}
          />

          <BookingsCard
            bookings={bookings}
            showCreateButton={canCreateBooking(currentUserRoles)}
            fetching={bookingFetching}
            error={bookingErr}
            highlightBooking={selectedBooking}
            onClick={(ref) => handleBookingsCardRowClick(ref)}
          />
        </div>
      </div>
    </BottomContentLayout>
  )
}
