import { Tag } from 'antd'

import { IconComponent } from 'components/Icons/IconComponent'
import { IconTypes } from 'components/Icons/IconsMap'

import './tag.css'

interface TagProps {
  color?: 'success' | 'error' | 'default' | 'warning'
  text: string
  icon?: IconTypes
}
export const TagComponent = ({ color = 'default', text, icon }: TagProps) => {
  return (
    <Tag color={color} icon={icon ? <IconComponent icon={icon} /> : <></>}>
      {text}
    </Tag>
  )
}
