import { IConfiguredSurcharge } from 'shared/interface/configured-surcharge.interface'

import { ConfiguredSurcharge } from 'proto/contract/v1/contract_pb'

import { fromSurchargeProto, toSurchargeProto } from './surcharge.mapper'

const fromConfiguredSurchargeProto = (
  configuredSurcharge: ConfiguredSurcharge,
): IConfiguredSurcharge => {
  return {
    configuredSurchargeId: configuredSurcharge.getConfiguredSurchargeId(),
    isRequired: configuredSurcharge.getIsRequired(),
    isIncluded: configuredSurcharge.getIsIncluded(),
    surcharge: fromSurchargeProto(configuredSurcharge.getSurcharge()!),
  }
}

const toConfiguredSurchargeProto = (
  configuredSurcharge: IConfiguredSurcharge,
): ConfiguredSurcharge => {
  const proto = new ConfiguredSurcharge()
  proto.setIsRequired(configuredSurcharge.isRequired)
  proto.setIsIncluded(configuredSurcharge.isIncluded)
  proto.setSurcharge(toSurchargeProto(configuredSurcharge.surcharge))
  return proto
}

export { fromConfiguredSurchargeProto, toConfiguredSurchargeProto }
