import React from 'react'
import { createBrowserRouter, redirect } from 'react-router-dom'

import { NavigationGuard } from 'App/NavigationGuard'

import { Home } from '../views/Home'
import { SetupUser } from '../views/Signup/SetupUser'

import AuthenticatedApp from '../App'
import AuthCallback from '../App/AuthCallback'
import { LoadingComponent } from '../App/Loading'
import { Login } from '../App/Login'
import { Main } from '../App/Main'
import { RouteNames } from './routes-map'

const availableRoutes = [
  {
    path: RouteNames.Setup,
    element: <SetupUser />,
  },
  {
    path: RouteNames.Loading,
    element: <LoadingComponent />,
  },
  {
    path: RouteNames.CallBack,
    element: <AuthCallback />,
  },
  {
    path: RouteNames.Login,
    element: <Login />,
  },
  {
    path: RouteNames.Main,
    element: (
      <AuthenticatedApp redirectTo={RouteNames.Login}>
        <NavigationGuard>
          <Main />
        </NavigationGuard>
      </AuthenticatedApp>
    ),
    children: [
      {
        path: RouteNames.Booking,
        async lazy() {
          const { BookingComponent } = await import(
            /*webpackChunkName: 'Booking'*/ '../views/Booking/View/View'
          )
          return {
            Component: BookingComponent,
          }
        },
      },
      {
        path: RouteNames.Bookings,

        async lazy() {
          const { BookingCardList } = await import(
            /*webpackChunkName: 'Booking'*/ '../views/Booking/List/BookingCardList'
          )
          return {
            Component: BookingCardList,
          }
        },
      },
      {
        path: RouteNames.BookingCreate,
        async lazy() {
          const { CreateBooking } = await import(
            /*webpackChunkName: 'Booking'*/ '../views/Booking/Create'
          )
          return { Component: CreateBooking }
        },
      },
      {
        path: RouteNames.BookingEdit,
        async lazy() {
          const { EditBooking } = await import(
            /*webpackChunkName: 'Booking'*/ '../views/Booking/Edit'
          )
          return { Component: EditBooking }
        },
      },
      {
        path: RouteNames.DraftsRef,
        async lazy() {
          const { BookingComponent } = await import(
            /*webpackChunkName: 'Booking'*/ '../views/Booking/View/View'
          )
          return { Component: BookingComponent }
        },
      },
      {
        path: RouteNames.Drafts,
        async lazy() {
          const { BookingCardList } = await import(
            /*webpackChunkName: 'Booking'*/ '../views/Booking/List/BookingCardList'
          )
          return { Component: BookingCardList }
        },
      },
      {
        path: RouteNames.DraftsCreate,
        async lazy() {
          const { CreateBooking } = await import(
            /*webpackChunkName: 'Booking'*/ '../views/Booking/Create'
          )
          return { Component: CreateBooking }
        },
      },
      {
        path: RouteNames.DraftsEdit,
        async lazy() {
          const { EditBooking } = await import(
            /*webpackChunkName: 'Booking'*/ '../views/Booking/Edit'
          )
          return { Component: EditBooking }
        },
      },

      {
        path: RouteNames.Orders,
        async lazy() {
          const { Orders } = await import(/*webpackChunkName: 'Orders'*/ '../views/Order/List')
          return { Component: Orders }
        },
      },
      {
        path: RouteNames.OrdersCreate,
        async lazy() {
          const { CreateOrder } = await import(
            /*webpackChunkName: 'Orders'*/ '../views/Order/Create'
          )
          return { Component: CreateOrder }
        },
      },
      {
        path: RouteNames.OrdersRef,
        async lazy() {
          const { OrderView } = await import(/*webpackChunkName: 'Orders'*/ '../views/Order/View')
          return { Component: OrderView }
        },
      },
      // contracts
      {
        path: RouteNames.Contracts,
        async lazy() {
          const { ContractContainer } = await import(
            /*webpackChunkName: 'Contracts'*/ '../views/Contracts/components/ContractContainer'
          )
          return { Component: ContractContainer }
        },
      },
      {
        path: RouteNames.ContractsCreate,
        async lazy() {
          const { CreateContractContainer } = await import(
            /*webpackChunkName: 'Contracts'*/ '../views/Contracts/components/CreateContractContainer'
          )
          return { Component: CreateContractContainer }
        },
      },
      {
        path: RouteNames.ContractsEdit,
        async lazy() {
          const { EditContractContainer } = await import(
            /*webpackChunkName: 'Contracts'*/ '../views/Contracts/components/EditContractContainer'
          )
          return { Component: EditContractContainer }
        },
      },
      // quotes
      {
        path: RouteNames.Quotes,
        async lazy() {
          const { BookingCardList } = await import(
            /*webpackChunkName: 'Booking'*/ '../views/Booking/List/BookingCardList'
          )
          return { Component: BookingCardList }
        },
      },
      {
        path: RouteNames.QuotesCreate,
        async lazy() {
          const { CreateBooking } = await import(
            /*webpackChunkName: 'Booking'*/ '../views/Booking/Create'
          )
          return { Component: CreateBooking }
        },
      },
      {
        path: RouteNames.QuotesRef,
        async lazy() {
          const { BookingComponent } = await import(
            /*webpackChunkName: 'Booking'*/ '../views/Booking/View/View'
          )
          return { Component: BookingComponent }
        },
      },
      {
        path: RouteNames.QuotesEdit,
        async lazy() {
          const { EditBooking } = await import(
            /*webpackChunkName: 'Booking'*/ '../views/Booking/Edit'
          )
          return { Component: EditBooking }
        },
      },
      // shipments
      {
        path: RouteNames.Shipments,
        async lazy() {
          const { Shipments } = await import(
            /*webpackChunkName: 'Shipments'*/ '../views/Shipment/List/List'
          )
          return { Component: Shipments }
        },
      },
      {
        path: RouteNames.ShipmentsRef,
        async lazy() {
          const { Shipment } = await import(
            /*webpackChunkName: 'Shipments'*/ '../views/Shipment/View/View'
          )
          return { Component: Shipment }
        },
      },
      {
        path: RouteNames.Invoices,
        async lazy() {
          const { Invoices } = await import(
            /*webpackChunkName: 'Finance'*/ '../views/Finance/Invoice/List'
          )
          return { Component: Invoices }
        },
      },
      {
        path: RouteNames.Costs,
        async lazy() {
          const { Costs } = await import(
            /*webpackChunkName: 'Finance'*/ '../views/Finance/Cost/List'
          )
          return { Component: Costs }
        },
      },
      {
        path: RouteNames.ArticeTypes,
        async lazy() {
          const { ArticleTypes } = await import(
            /*webpackChunkName: 'Finance'*/ '../views/Finance/ArticleTypes/List/List'
          )
          return { Component: ArticleTypes }
        },
      },
      {
        path: RouteNames.Profatibility,
        async lazy() {
          const { Profitability } = await import(
            /*webpackChunkName: 'Finance'*/ '../views/Finance/Profitability/List'
          )
          return { Component: Profitability }
        },
      },
      {
        path: RouteNames.Products,
        async lazy() {
          const { Products } = await import(
            /*webpackChunkName: 'Products'*/ '../views/Product/List'
          )
          return { Component: Products }
        },
      },
      {
        path: RouteNames.Addressess,
        async lazy() {
          const { Addresses } = await import(
            /*webpackChunkName: 'Addresses'*/ '../views/Addresses/List'
          )
          return { Component: Addresses }
        },
      },
      {
        path: RouteNames.Reports,
        async lazy() {
          const { Reports } = await import(/*webpackChunkName: 'Reports'*/ '../views/Reports')
          return { Component: Reports }
        },
      },

      {
        path: RouteNames.ReportsOrderProgress,
        async lazy() {
          const { OrderProgress } = await import(
            /*webpackChunkName: 'Reports'*/ '../views/Reports/OrderProgress'
          )
          return { Component: OrderProgress }
        },
      },
      {
        path: RouteNames.ReportsBookingProgress,
        async lazy() {
          const { BookingProgress } = await import(
            /*webpackChunkName: 'Reports'*/ '../views/Reports/BookingProgress'
          )
          return { Component: BookingProgress }
        },
      },
      {
        path: RouteNames.ReportsShipmentMode,
        async lazy() {
          const { ShipmentMode } = await import(
            /*webpackChunkName: 'Reports'*/ '../views/Reports/ShipmentMode'
          )
          return { Component: ShipmentMode }
        },
      },
      {
        path: RouteNames.ReportsEmission,
        async lazy() {
          const { BookingEmissions } = await import(
            /*webpackChunkName: 'Reports'*/ '../views/Reports/Emissions'
          )
          return { Component: BookingEmissions }
        },
      },
      {
        path: RouteNames.Admin,
        children: [
          {
            path: 'managed/:to?',
            async lazy() {
              const { CreateAndEditSupplier } = await import(
                /*webpackChunkName: 'Admin Supplier'*/ '../views/Admin/Supplier/ManageSupplier/CreateAndEditSupplier'
              )
              return { Component: CreateAndEditSupplier }
            },
          },
          {
            path: 'list',
            async lazy() {
              const { SupplierList } = await import(
                /*webpackChunkName: 'Admin Supplier List'*/ '../views/Admin/Supplier/SupplierList'
              )
              return { Component: SupplierList }
            },
          },
          {
            path: '',
            async lazy() {
              const { SupplierList } = await import(
                /*webpackChunkName: 'Admin Supplier List'*/ '../views/Admin/Supplier/SupplierList'
              )
              return { Component: SupplierList }
            },
          },
        ],
        async lazy() {
          const { AdminView } = await import(
            /*webpackChunkName: 'Admin'*/ '../views/Admin/AdminView'
          )
          return { Component: AdminView }
        },
      },
      {
        path: RouteNames.AdminUsers,
        async lazy() {
          const { AdminView } = await import(
            /*webpackChunkName: 'Admin'*/ '../views/Admin/AdminView'
          )
          return { Component: AdminView }
        },
      },
      {
        path: RouteNames.Profile,
        async lazy() {
          const { Profile } = await import(/*webpackChunkName: 'Profile'*/ '../views/Profile')
          return { Component: Profile }
        },
      },
      {
        path: RouteNames.Privacy,
        async lazy() {
          const { Privacy } = await import(/*webpackChunkName: 'Privacy'*/ '../views/Privacy')
          return { Component: Privacy }
        },
      },
      {
        path: RouteNames.Surcharges,
        async lazy() {
          const { SurchargesContainer } = await import(
            /*webpackChunkName: 'Surcharges'*/ '../views/Surcharges/components/SurchargesView'
          )
          return { Component: SurchargesContainer }
        },
      },
      {
        path: '',
        element: <Home />,
      },
      {
        path: '*',
        loader: () => {
          return redirect(RouteNames.Home)
        },
      },
    ],
  },
]

const getRoutes = {
  routes: createBrowserRouter(availableRoutes),
}

export { getRoutes, availableRoutes }
