import React, { useState } from 'react'

import { WarningAlert } from 'components/Alerts'
import { LinkComponent } from 'components/Links/Link'
import { Heading, TypographyComponent } from 'components/Typography'

import { logError } from 'helpers/logger'

import './ErrorBoundary.css'

interface ErrorBoundaryProps {
  children?: JSX.Element
}
export const ErrorBoundaryComponent = ({ children }: ErrorBoundaryProps) => {
  const [errorMessage] = useState<ErrorEvent>()
  const errorLogger = (error: ErrorEvent) => {
    logError(error)
  }

  React.useEffect(() => {
    window.addEventListener('error', errorLogger)
    return () => {
      window.removeEventListener('error', errorLogger)
    }
  }, [])

  return (
    <>
      {!!errorMessage && (
        <div className="error-boundary__container">
          <Heading text="We're sorry - something went wrong" />
          <div className="error-boundary__layout">
            <TypographyComponent
              emphasis="high"
              fontWeight="normal"
              size="small"
              text={'Our team has been notified. Try refreshing the page or return to home page.'}
            />
            <LinkComponent to="/">Home</LinkComponent>
          </div>

          <p>{errorMessage.message}</p>

          <WarningAlert
            description={
              'AdBlock or similar tools may block and disable the error reporting feature. In this case please turn your AdBlock off or add this website to exceptions.'
            }
            showIcon={false}
          />
        </div>
      )}

      {!!!errorMessage && children}
    </>
  )
}
