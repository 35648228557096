import { TypedUseSelectorHook } from 'react-redux'
import { useSelector } from 'react-redux'

import { addBreadcrumb } from '@sentry/browser'
import { Dispatch, Middleware, Store, applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootSaga from '../sagas'
import { customContext } from '../sagas/context'
import createRootReducer, { RootState } from './reducer'

// Custom logging of Protobuf objects using toObject().
const devToolsOptions = {
  serialize: {
    replacer: (key: any, value: any) => {
      if (value && typeof value.toObject === 'function') {
        return value.toObject()
      }
      return value
    },
  },
}

function createSentryMiddleware() {
  const sentryMiddleware: Middleware = () => (next: Dispatch) => (action) => {
    addBreadcrumb({
      message: action.type,
      category: 'redux-action',
      level: 'info',
      data: {
        payload: action.payload,
      },
    })
    return next(action)
  }

  return sentryMiddleware
}

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devToolsOptions)) ||
  compose

let store: Store | null = null
export default function configureStore(initialState?: RootState): Store {
  const sagaMiddleware = createSagaMiddleware({
    context: {
      customContext,
    },
  })
  const sentryMiddleware = createSentryMiddleware()

  const reducer = createRootReducer()
  const middleware = [sentryMiddleware, sagaMiddleware]
  const enhancer = composeEnhancers(applyMiddleware(...middleware))
  const tempStore = createStore(reducer, initialState!, enhancer)
  sagaMiddleware.run(rootSaga)

  store = tempStore
  return tempStore
}

const getStore = (): Store => {
  return store ? store : configureStore()
}

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export { getStore, useAppSelector }
