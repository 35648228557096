import React from 'react'

import {
  ArrowLeftOutlined,
  AuditOutlined,
  BarcodeOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DiffFilled,
  DollarOutlined,
  DownOutlined,
  DownloadOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  InboxOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  LoginOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons'

import {
  AddressBook,
  Air,
  AngleDown,
  AngleLeft,
  AngleRight,
  AngleUp,
  Box,
  Boxes,
  Building,
  ClipboardList,
  Coins,
  Comment,
  Courier,
  Edit,
  Forward,
  InfoCircle,
  InvoiceDollar,
  Like,
  PenSquare,
  Pencil,
  Poll,
  Print,
  ProfileUser,
  Rail,
  Road,
  Sea,
  SignOut,
  StickyNote,
  Tag,
  TimesCircleRegular,
  Trash,
  UserCog,
  closeIcon,
} from 'components/icons'

const CustomIconMap = {
  Air: Air,
  Sea: Sea,
  AddressBook: AddressBook,
  Boxes: Boxes,
  Box: Box,
  Building: Building,
  ClipboardList: ClipboardList,
  Coins: Coins,
  Courier: Courier,
  Pencil: Pencil,
  Poll: Poll,
  Rail: Rail,
  Road: Road,
  UserCog: UserCog,
  Comment: Comment,
  SignOut: SignOut,
  StickyNote: StickyNote,
  Tag: Tag,
  Edit: Edit,
  Print: Print,
  Trash: Trash,
  Forward: Forward,
  InfoCircle: InfoCircle,
  ProfileUser: ProfileUser,
  Like: Like,
  TimesCircleRegular: TimesCircleRegular,
  AngleUp: AngleUp,
  AngleDown: AngleDown,
  AngleLeft: AngleLeft,
  AngleRight: AngleRight,
  PenSquare: PenSquare,
  InvoiceDollar: InvoiceDollar,
  closeIcon: closeIcon,
} as const

const IconMap = {
  CheckOutlined: <CheckOutlined />,
  EllipsisOutlined: <EllipsisOutlined />,
  AuditOutlined: <AuditOutlined />,
  BarcodeOutlined: <BarcodeOutlined />,
  DollarOutlined: <DollarOutlined />,
  FileOutlined: <FileOutlined />,
  DeleteOutlined: <DeleteOutlined />,
  DownOutlined: <DownOutlined />,
  DownloadOutlined: <DownloadOutlined />,
  ArrowLeftOutlined: <ArrowLeftOutlined />,
  ClockCircleOutlined: <ClockCircleOutlined />,
  CloseCircleOutlined: <CloseCircleOutlined />,
  CloseOutlined: <CloseOutlined />,
  DiffFilled: <DiffFilled />,
  EditOutlined: <EditOutlined />,
  ExclamationCircleOutlined: <ExclamationCircleOutlined />,
  InfoCircleOutlined: <InfoCircleOutlined />,
  LoadingOutlined: <LoadingOutlined />,
  LoginOutlined: <LoginOutlined />,
  MenuFoldOutlined: <MenuFoldOutlined />,
  MenuOutlined: <MenuOutlined />,
  MenuUnfoldOutlined: <MenuUnfoldOutlined />,
  PlusCircleOutlined: <PlusCircleOutlined />,
  UserOutlined: <UserOutlined />,
  PlusOutlined: <PlusOutlined />,
  InboxOutlined: <InboxOutlined />,
  SettingOutlined: <SettingOutlined />,
} as const

type IconTypes = keyof typeof IconMap

type CustomIconTypes = keyof typeof CustomIconMap

export type { IconTypes, CustomIconTypes }
export { IconMap, CustomIconMap }
