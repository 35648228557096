import * as React from 'react'

import Icon from '@ant-design/icons'
import { Button, Divider, Empty, Row, Typography } from 'antd'
import * as countryData from 'country-data'

import * as bookingv1 from '../../../proto/booking/v1/booking_pb'

import { LinkComponent } from 'components/Links/Link'
import { TransportMode } from 'components/TransportModes/TransportMode'

import { getBookingTotalWeight, transportSubmodeTypeLabel } from '../../../helpers/booking'
import { distanceString } from '../../../helpers/distance'

import { ToggleNotch } from '../../ToggleNotch'
import { AngleLeft, AngleRight } from '../../icons'
import './styles.css'

const { Text } = Typography

interface PopupProps {
  booking?: bookingv1.Booking
  expanded: boolean
  onExpandToggle: () => void
  onPreviousBooking?: () => void
  onNextBooking?: () => void
  paginationCurrentPage?: number
  paginationTotal?: number
}

const getCountry = (countryId?: string) => {
  let countryName = ''
  if (countryId) {
    const c = countryData.countries.all.find((c) => c.alpha2.toLowerCase() === countryId)

    countryName = c ? c.name : ''
  }

  return countryName
}

const getTotalColli = (booking: bookingv1.Booking): number => {
  return booking
    .getTransportSubmodeItemsList()
    .flatMap((s) => s.getColliList())
    .reduce((a, c) => (a += c.getQuantity()), 0)
}

export const Popup: React.FC<PopupProps> = ({
  booking,
  expanded,
  onExpandToggle,
  onPreviousBooking,
  onNextBooking,
  paginationCurrentPage,
  paginationTotal,
}) => {
  const showPagination = paginationCurrentPage && paginationTotal && paginationTotal > 1

  return (
    <div className="map-popup">
      <div className={'map-popup-info-bar' + (!expanded ? '' : ' hidden')}>
        <Text type="secondary">Click on a booking on the map for details</Text>
      </div>
      <div
        className={
          'map-popup-card' +
          (showPagination ? ' with-pagination' : '') +
          (expanded ? '' : ' hidden')
        }
      >
        {(booking && (
          <div className="map-popup-card-container">
            <section className="map-popup-card-section">
              <h1 className="section-title">{booking.getBookingRef()}</h1>
              <Text type="secondary" className="section-sub-title">
                Current Booking
              </Text>
            </section>
            <Divider className="divider" />
            <section className="map-popup-card-section">
              <h3 className="section-title secondary">From</h3>
              {booking.getSender() && (
                <>
                  <Text className="organization-name">{booking.getSender()?.getName()}</Text>
                  <Text className="section-text">
                    {booking.getSender()?.getCity()},{' '}
                    {getCountry(booking.getSender()?.getCountryId())}
                  </Text>
                </>
              )}
            </section>
            <section className="map-popup-card-section">
              <h3 className="section-title secondary">To</h3>
              {booking.getReceiver() && (
                <>
                  <Text className="organization-name">{booking.getReceiver()?.getName()}</Text>
                  <Text className="section-text">
                    {booking.getReceiver()?.getCity()},{' '}
                    {getCountry(booking.getReceiver()?.getCountryId())}
                  </Text>
                </>
              )}
            </section>
            <section className="map-popup-card-section">
              <TransportMode
                modeEnum={booking.getNominatedTransportMode()}
                iconStyle={{ color: '#f95c50' }}
                labelClassName="transport-mode-label"
                verticalCenterIconAndText
                withText
              />
            </section>
            <Divider className="divider divider-second" />
            <section className="map-popup-card-section">
              <Row>
                <Text strong className="label-col">
                  Submode
                </Text>
                <Text className="section-text">
                  {transportSubmodeTypeLabel[booking.getTransportSubmodeType()]}
                </Text>
              </Row>
              <Row>
                <Text strong className="label-col">
                  Amount
                </Text>
                <Text className="section-text">{getTotalColli(booking)} Colli</Text>
              </Row>
              <Row>
                <Text strong className="label-col">
                  Gross Weight
                </Text>
                <Text className="section-text">{getBookingTotalWeight(booking)} kg</Text>
              </Row>
              {booking.getDirectDistance() > 0 && !booking.getDistance() && (
                <Row>
                  <Text strong className="label-col">
                    Direct Distance
                  </Text>
                  <Text className="section-text">{booking.getDirectDistance()} km</Text>
                </Row>
              )}
              {booking.getDistance() > 0 && !!booking.getDistanceUnit() && (
                <Row>
                  <Text strong className="label-col">
                    Distance
                  </Text>
                  <Text className="section-text">
                    {distanceString(booking.getDistance(), booking.getDistanceUnit())}
                  </Text>
                </Row>
              )}
            </section>
            <section className="map-popup-card-section section-with-button">
              <LinkComponent to={`/bookings/${booking.getBookingRef()}`}>
                <Button ghost shape="round" size="large" className="section-button">
                  Go to Booking
                </Button>
              </LinkComponent>
            </section>
            {paginationCurrentPage && paginationTotal && paginationTotal > 1 && (
              <section className="map-popup-card-section section-with-pagination">
                <div className="pagination-container">
                  <Button
                    className="pagination-button"
                    onClick={onPreviousBooking}
                    disabled={paginationCurrentPage === 1}
                  >
                    <Icon component={AngleLeft} className="pagination-icon" />
                  </Button>
                  <Text className="section-text pagination-text">
                    {paginationCurrentPage} of {paginationTotal} bookings
                  </Text>
                  <Button
                    className="pagination-button"
                    onClick={onNextBooking}
                    disabled={paginationCurrentPage === paginationTotal}
                  >
                    <Icon component={AngleRight} className="pagination-icon" />
                  </Button>
                </div>
              </section>
            )}
          </div>
        )) || (
          <Row className="placeholder">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Click on a booking on the map for details"
            />
          </Row>
        )}
      </div>
      <div className="map-popup-footer">
        <div className="notch-base"></div>
        <ToggleNotch expanded={expanded} onExpandToggle={onExpandToggle} red />
      </div>
    </div>
  )
}
