import { Owner } from 'proto/common/owner_pb'
import { Organization } from 'proto/iam/v1/organization_pb'
import { User } from 'proto/iam/v1/user_pb'

export const canCreateOrder = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.SHIPPER) >= 0 ||
  roles.indexOf(User.Role.ORGANIZATION) >= 0 ||
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const shouldCreateOrder = (roles: User.Role[], userOrg?: Organization): boolean =>
  !(
    roles.indexOf(User.Role.SHIPPER) >= 0 &&
    !(roles.indexOf(User.Role.TRANSPORTER) >= 0) &&
    !!userOrg &&
    userOrg.getOrganization() === 'nakd'
  )

export const canCreateBooking = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0 ||
  roles.indexOf(User.Role.SHIPPER) >= 0 ||
  roles.indexOf(User.Role.ORGANIZATION) >= 0

export const canEditDraftsAndQuotes = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0 ||
  roles.indexOf(User.Role.SHIPPER) >= 0 ||
  roles.indexOf(User.Role.ORGANIZATION) >= 0

export const canEditBookedBookings = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canDeleteDraft = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0 || roles.indexOf(User.Role.ORGANIZATION) >= 0

export const canQuoteBooking = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canEditNominationWhileAcceptBooking = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canAcceptBookedBooking = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canSeeShipments = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canSeeShipmentPrice = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0 || roles.indexOf(User.Role.ORGANIZATION) >= 0

export const canSeePrice = (owner?: Owner, user?: User): boolean => {
  if (!owner || !user) {
    return false
  }
  return owner.getBranchId() === user.getBranchId()
}

export const canSeeProduct = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.RECEIVER) >= 0 ||
  roles.indexOf(User.Role.CONTROLLER) >= 0 ||
  roles.indexOf(User.Role.ORGANIZATION) >= 0 ||
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canCreateReturn = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0 || roles.indexOf(User.Role.SHIPPER) >= 0

export const canCreatePurchase = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0 || roles.indexOf(User.Role.ORGANIZATION) >= 0

export const canConfigureBookingCreatedNotification = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0 || roles.indexOf(User.Role.ORGANIZATION) >= 0

export const canConfigureBookingEditedNotification = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0 || roles.indexOf(User.Role.ORGANIZATION) >= 0

export const canConfigureBookingBookedNotification = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canConfigureBookingAcceptedNotification = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0 || roles.indexOf(User.Role.ORGANIZATION) >= 0

export const canConfigureBookedBookingNotification = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canConfigureBookingImportFailedNotification = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canConfigureOrderCreatedNotification = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0 ||
  roles.indexOf(User.Role.CONTROLLER) >= 0 ||
  roles.indexOf(User.Role.ORGANIZATION) >= 0 ||
  roles.indexOf(User.Role.SHIPPER) >= 0 ||
  roles.indexOf(User.Role.RECEIVER) >= 0

export const canConfigureOrderUpdatedNotification = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0 ||
  roles.indexOf(User.Role.CONTROLLER) >= 0 ||
  roles.indexOf(User.Role.ORGANIZATION) >= 0 ||
  roles.indexOf(User.Role.SHIPPER) >= 0

export const canConfigureMessageCreatedNotification = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.CONTROLLER) >= 0 ||
  roles.indexOf(User.Role.ORGANIZATION) >= 0 ||
  roles.indexOf(User.Role.SHIPPER) >= 0 ||
  roles.indexOf(User.Role.RECEIVER) >= 0 ||
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canDeleteDocument = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canConfigureDocumentUploadedNotification = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.CONTROLLER) >= 0 ||
  roles.indexOf(User.Role.ORGANIZATION) >= 0 ||
  roles.indexOf(User.Role.SHIPPER) >= 0 ||
  roles.indexOf(User.Role.RECEIVER) >= 0 ||
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canConfigureInvoiceIssuedNotification = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canConfigureInvoiceOverdueNotification = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0 || roles.indexOf(User.Role.FINANCE) >= 0

export const canConfigureShipmentNotification = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canConfigureBookingQuoteNotification = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.TRANSPORTER) >= 0

export const canConfigureOwner = (user: User): boolean =>
  user.getRolesList().indexOf(User.Role.TRANSPORTER) >= 0 && !user.getOrganizationId()

export const canEditInvoiceNote = (roles: User.Role[]): boolean =>
  roles.indexOf(User.Role.FINANCE) >= 0

export const canAcceptContract = (): boolean => false

export const AllowedForAll = [
  User.Role.ORGANIZATION,
  User.Role.SHIPPER,
  User.Role.RECEIVER,
  User.Role.CONTROLLER,
  User.Role.TRANSPORTER,
  User.Role.FINANCE,
  User.Role.MANAGER,
  User.Role.ADMIN,
  User.Role.SALES,
  User.Role.API,
]

export const AllowedForAllExceptAdmin = [
  User.Role.ORGANIZATION,
  User.Role.SHIPPER,
  User.Role.RECEIVER,
  User.Role.CONTROLLER,
  User.Role.TRANSPORTER,
  User.Role.FINANCE,
  User.Role.MANAGER,
  User.Role.SALES,
]

export const AllowedForTransporterShipperOrganization = [
  User.Role.TRANSPORTER,
  User.Role.SHIPPER,
  User.Role.ORGANIZATION,
]
