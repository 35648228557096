import { CreateSurchargeType, ISurcharge } from 'shared/interface/surcharge.interface'

import { ActionsUnion, createAction } from 'store/helpers'

export const CREATE_SURCHARGE = 'CREATE_SURCHARGE'
export const CREATE_SURCHARGE_RESPONSE = 'CREATE_SURCHARGE_RESPONSE'
export const CREATE_SURCHARGE_ERROR = 'CREATE_SURCHARGE_ERROR'
export const LIST_SURCHARGES = 'LIST_SURCHARGES'
export const LIST_SURCHARGES_RESPONSE = 'LIST_SURCHARGES_RESPONSE'
export const SURCHARGE_ERROR = 'SURCHARGE_ERROR'
export const SET_IS_FETCHING = 'SET_IS_FETCHING'

export const Actions = {
  listSurcharges: () => createAction(LIST_SURCHARGES, {}),
  listSurchargesResponse: (surcharges: ISurcharge[]) =>
    createAction(LIST_SURCHARGES_RESPONSE, { surcharges }),
  createSurcharge: (surcharge: CreateSurchargeType, onSuccess: () => void) =>
    createAction(CREATE_SURCHARGE, { surcharge, onSuccess }),
  createSurchargeResponse: (surcharge: ISurcharge) =>
    createAction(CREATE_SURCHARGE_RESPONSE, { surcharge }),
  createSurchargeError: (message: any) => createAction(CREATE_SURCHARGE_ERROR, { message }),
  surchargeError: (message: any) => createAction(SURCHARGE_ERROR, { message }),
  setIsFetching: (isFetching: boolean) => createAction(SET_IS_FETCHING, { isFetching }),
}

export type ActionTypes = ActionsUnion<typeof Actions>
