import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'

import * as Sentry from '@sentry/browser'
import { ConfigProvider, theme } from 'antd'
import locale from 'antd/es/locale/en_GB'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { getRoutes } from 'route/routes'

import { getStore } from 'store'

import { ErrorBoundaryComponent } from 'components/Error/ErrorBoundary'

import '../styles.css'

// App config, loaded by index.html as env.js form the API.
interface Env {
  AUTH0_DOMAIN: string
  AUTH0_CLIENT_ID: string
  AUTH0_AUDIENCE: string
  AUTH0_CONNECTION: string
  GOOGLE_MAPS_API_KEY: string
  LAUNCH_DARKLY_CLIENT_KEY: string
  ENVIRONMENT: string
  VERSION: string
}

declare global {
  interface Window {
    env: Env
  }
}

// --intereast-red: #fa5a50;
// --intereast-dark-red: #430b27;
// --intereast-purple: #8b5af7;
// --intereast-light-purple: #e7d5ea;
// --intereast-light-cream: #f6f3ee;
// --intereast-dark-cream: #f0eade;
const colors = {
  red: '#fa5a50',
  darkRed: '#430b27',
  purple: '#8b5af7',
  lightPurple: '#e7d5ea',
  lightCream: '#f6f3ee',
  darkCream: '#f0eade',
}

type ThemeData = {
  borderRadius: number
  colorPrimary: string
  colorSecondary: string
  Button: {
    colorPrimary: string
    algorithm?: boolean
  }
  Table: {
    headerColor: string
    colorPrimary: string
    fontWeightStrong: number
  }
  Checkbox: {
    colorPrimary: string
  }
}

const defaultData: ThemeData = {
  borderRadius: 4,
  colorPrimary: colors.darkRed,
  colorSecondary: colors.lightPurple,
  Button: {
    colorPrimary: colors.darkRed,
    algorithm: false,
  },
  Table: {
    headerColor: colors.darkRed,
    colorPrimary: colors.darkRed,
    fontWeightStrong: 500,
  },
  Checkbox: {
    colorPrimary: colors.red,
  },
}

// eslint-disable-next-line react/function-component-definition
function App() {
  // Default env values.
  window.env = window.env || {}
  window.env.AUTH0_DOMAIN = window.env.AUTH0_DOMAIN || ''
  window.env.AUTH0_CLIENT_ID = window.env.AUTH0_CLIENT_ID || ''
  window.env.AUTH0_AUDIENCE = window.env.AUTH0_AUDIENCE || ''
  window.env.AUTH0_CONNECTION = window.env.AUTH0_CONNECTION || ''
  window.env.GOOGLE_MAPS_API_KEY = window.env.GOOGLE_MAPS_API_KEY || ''
  window.env.LAUNCH_DARKLY_CLIENT_KEY = window.env.LAUNCH_DARKLY_CLIENT_KEY || ''
  window.env.ENVIRONMENT = window.env.ENVIRONMENT || 'unknown'
  window.env.VERSION = window.env.VERSION || 'unknown'

  const devEnv = process.env.NODE_ENV === 'development'

  !devEnv &&
    TagManager.initialize({
      gtmId: 'GTM-KC54GWH',
    })

  // Sentry.io error reporting.
  !devEnv &&
    Sentry.init({
      dsn: 'https://b01a42ff1ea44bd19c1fd13c8d2b1025@sentry.io/1215566',
      environment: window.env.ENVIRONMENT,
      release: window.env.VERSION,
    })

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        algorithm: [theme.compactAlgorithm],
        token: {
          borderRadius: defaultData.borderRadius,
          colorPrimary: colors.red,
        },
        components: {
          Button: {
            colorPrimary: defaultData.Button.colorPrimary,
            algorithm: defaultData.Button.algorithm,
            defaultHoverBorderColor: colors.red,
            defaultHoverColor: colors.red,
            colorLink: colors.red,
          },
          Spin: {
            colorPrimary: defaultData.Button.colorPrimary,
          },
          Steps: {
            colorPrimary: defaultData.Button.colorPrimary,
            algorithm: false,
          },
          Collapse: {
            fontSizeIcon: 14,
          },
          DatePicker: {
            colorPrimary: colors.red,
          },
          Tabs: {
            colorPrimary: defaultData.Button.colorPrimary,
            itemHoverColor: colors.red,
          },
          Menu: {
            colorPrimary: colors.red,
            algorithm: true,
          },
          Table: {
            headerColor: defaultData.Table.headerColor,
            colorPrimary: defaultData.Button.colorPrimary,
            fontWeightStrong: defaultData.Table.fontWeightStrong,
          },
          Checkbox: {
            colorPrimary: defaultData.Checkbox.colorPrimary,
          },
          Switch: {
            colorPrimary: defaultData.Checkbox.colorPrimary,
          },
        },
      }}
    >
      <ErrorBoundaryComponent>
        <Provider store={getStore()}>
          <RouterProvider router={getRoutes.routes} />
        </Provider>
      </ErrorBoundaryComponent>
    </ConfigProvider>
  )
}

// Update the export default to use your environment-specific client ID and a sample context:
export default withLDProvider({
  clientSideID: window.env.LAUNCH_DARKLY_CLIENT_KEY as string,
  context: {
    kind: 'user',
    key: 'Elise system',
  },
})(App)
