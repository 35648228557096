import {
  Auth0DecodedHash,
  Auth0Error,
  Auth0UserProfile,
  AuthOptions,
  DbSignUpOptions,
  WebAuth,
} from 'auth0-js'
import * as grpcWeb from 'grpc-web'

import { AuthSignup } from '../types/signup'

export interface UserProfile extends Auth0UserProfile {}

// Local storage keys.
enum LocalStorageKey {
  ACCESS_TOKEN = 'access_token',
  ID_TOKEN = 'id_token',
  EXPIRES_AT = 'expires_at',
  PROFILE = 'profile',
  REDIRECT_URL = 'redirect_url',
}

export default class Auth {
  conf: AuthOptions
  auth0: WebAuth
  connection: string

  constructor(domain: string, audience: string, clientID: string, connection: string) {
    this.conf = {
      domain,
      audience,
      clientID,
      redirectUri: window.location.protocol + '//' + window.location.host + '/callback',
      responseType: 'token id_token',
      scope: 'openid profile email',
    }
    this.auth0 = new WebAuth(this.conf)
    this.connection = connection
  }

  login() {
    localStorage.setItem(LocalStorageKey.REDIRECT_URL, window.location.pathname)
    this.auth0.authorize()
  }

  signup(email: string): Promise<AuthSignup> {
    const password = generatePassword()
    const connection = this.connection
    return new Promise<AuthSignup>((resolve, reject) => {
      const signupOption: DbSignUpOptions = {
        email: email,
        password: password,
        connection: connection,
      }
      this.auth0.signup(signupOption, (err, result) => {
        if (err) {
          reject(err.errorDescription)
        }
        if (result) {
          if (connection === 'staging') {
            console.log('––––––––––––––––––––––––––––––––––//')
            console.log('New user created with credentials.')
            console.log('- email: ', result.email)
            console.log('- password: ', result.Id)
            console.log('If you are creating it to use in dev, consider clean it after use.')
            console.log('––––––––––––––––––––––––––––––––––//')
          }
          const newSignup: AuthSignup = {
            email: result.email,
            id: 'auth0|' + result.Id,
          }
          resolve(newSignup)
        }
      })
    })
  }

  logout() {
    localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN)
    localStorage.removeItem(LocalStorageKey.ID_TOKEN)
    localStorage.removeItem(LocalStorageKey.EXPIRES_AT)
    localStorage.removeItem(LocalStorageKey.PROFILE)
  }

  authenticate(): Promise<Auth0UserProfile> {
    return new Promise<Auth0UserProfile>((resolve) => {
      this.auth0.parseHash((err, authResult) => resolve(this.authorize(err, authResult)))
    })
  }

  refresh(): Promise<Auth0UserProfile> {
    return new Promise<Auth0UserProfile>((resolve) => {
      this.auth0.checkSession({}, (err, authResult) => resolve(this.authorize(err, authResult)))
    })
  }

  private authorize(
    err: Auth0Error | null,
    authResult: Auth0DecodedHash | null,
  ): Promise<Auth0UserProfile> {
    return new Promise<Auth0UserProfile>((resolve, reject) => {
      if (err) {
        reject(new Error(err.errorDescription))
        return
      }

      try {
        setSession(authResult)
      } catch (err) {
        reject(err)
        return
      }

      this.auth0.client.userInfo(accessToken(), (profileErr, p) => {
        if (profileErr) {
          reject(err)
          return
        }
        setProfile(p)
        resolve(p)
      })
    })
  }
}

export function authMetadata(): grpcWeb.Metadata {
  return { Authorization: `Bearer ${accessToken()}` }
}

export function accessToken(): string {
  const t = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN)
  return t ? t : ''
}

export function expiresAt(): Date {
  const expJS = localStorage.getItem(LocalStorageKey.EXPIRES_AT)
  return expJS ? new Date(JSON.parse(expJS)) : new Date(0)
}

export function profile(): Auth0UserProfile {
  const p = localStorage.getItem(LocalStorageKey.PROFILE)
  return p ? JSON.parse(p) : {}
}

export function redirectURL(): string {
  const url = localStorage.getItem(LocalStorageKey.REDIRECT_URL)
  localStorage.removeItem(LocalStorageKey.REDIRECT_URL)
  return url ? url : ''
}

function setSession(authResult: Auth0DecodedHash | null) {
  if (!authResult || !authResult.expiresIn || !authResult.accessToken || !authResult.idToken) {
    throw new Error('incomplete auth result')
  }
  const expAt = JSON.stringify(authResult.expiresIn * 1000 + Date.now())
  localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, authResult.accessToken)
  localStorage.setItem(LocalStorageKey.ID_TOKEN, authResult.idToken)
  localStorage.setItem(LocalStorageKey.EXPIRES_AT, expAt)
}

function setProfile(p: Auth0UserProfile) {
  localStorage.setItem(LocalStorageKey.PROFILE, JSON.stringify(p))
}

function generatePassword() {
  const length = 16
  const charset = 'abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ123456789'
  let retVal = ''
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }
  return retVal
}
