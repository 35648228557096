import { DateTime, Settings, WeekdayNumbers } from 'luxon'

import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

interface DateTimeConfig {
  locale: string
  firstDay: WeekdayNumbers
  minimalDays: WeekdayNumbers
  weekend: WeekdayNumbers[]
  defaultZone: string
}

const dateTime = (date?: string) => {
  return date ? DateTime.fromISO(date) : DateTime.local()
}

const timestampToDateTime = (timestamp?: Timestamp) => {
  return timestamp ? DateTime.fromSeconds(timestamp.getSeconds()).toISO() : undefined
}

const dateTimeUtc = (date?: string) => {
  return date ? DateTime.fromISO(date, { zone: 'utc' }) : DateTime.utc()
}

const dateStringToTimeStamp = (date: string) => {
  return toTimestamp(dateTime(date))
}

const stringToTimeStamp = (date: string) => {
  return toTimestamp(dateTime(date))
}

const toTimestamp = (dateTime: DateTime): Timestamp => {
  const ts = new Timestamp()
  ts.setSeconds(Math.floor(dateTime.toSeconds()))
  return ts
}

const createDateTimeFromDateAndTime = (
  date: DateTime | string | null,
  time: string | null,
): DateTime => {
  if (!date || !time) {
    return DateTime.now()
  }
  const dateTime = typeof date === 'string' ? DateTime.fromISO(date) : date
  return dateTime.set({ hour: parseInt(time.split(':')[0]), minute: parseInt(time.split(':')[1]) })
}

/**
 * Convert seconds to DateTime. Can be used with timestamp from google.protobuf.Timestamp if you convert it to seconds.
 */
const dateTimeFromSeconds = (seconds?: number) => {
  return seconds ? DateTime.fromSeconds(seconds) : DateTime.local()
}

const formatDateTime = (date: DateTime, format?: DateTimeFormats) => {
  return date.toLocaleString(format)
}

const toFormat = (dateTime: DateTime, format: LocaleFormats) => {
  return dateTime.toFormat(format)
}

const formateDateTimeString = (
  dateTime?: string,
  format: LocaleFormats = LOCALE_FORMATS.DAYMONTHYEAR,
) => {
  return dateTime ? DateTime.fromISO(dateTime).toFormat(format) : ''
}

const configureDateTime = (config: DateTimeConfig) => {
  Settings.defaultLocale = config.locale ?? 'se'
  Settings.defaultWeekSettings = {
    firstDay: config.firstDay ?? 1,
    minimalDays: config.minimalDays ?? 1,
    weekend: config.weekend ?? [6, 7],
  }
  Settings.defaultZone = config.defaultZone ?? 'Europe/Stockholm'
}

/**
 * MONTH: 'LLL' => Jan, Feb, Mar
 *
 * MONTH_AS_NUMBER: 'MM' => 01, 02, 03
 */
const LOCALE_FORMATS = {
  DAY: 'dd',
  MONTH: 'LLL', // Jan, Feb, Mar
  MONTH_AS_NUMBER: 'MM', // 01, 02, 03
  YEAR: 'yyyy',
  DAYMONTH: 'dd-MM',
  DAYMONTHYEAR: 'yyyy-MM-dd',
  DAYMONTHYEARHOUR: 'yyyy-MM-dd HH:mm',
  DAYMONTHYEARHOURSEC: 'yyyy-MM-dd HH:mm:ss',
  MONTHYEAR: 'LLL yyyy',
  HOURMINUTE: 'HH:mm',
} as const
type LocaleFormats = (typeof LOCALE_FORMATS)[keyof typeof LOCALE_FORMATS]

/**
 * Some of the formats but more can be found here https://moment.github.io/luxon/#/formatting
 */
const FORMAT = {
  DATE_SHORT: DateTime.DATE_SHORT,
  DATE_FULL: DateTime.DATE_FULL,
  DATE_HUGE: DateTime.DATE_HUGE,
  TIME_SIMPLE: DateTime.TIME_SIMPLE,
  TIME_WITH_SECONDS: DateTime.TIME_WITH_SECONDS,
  TME_24_SIMPLE: DateTime.TIME_24_SIMPLE,
  TIME_24_WITH_SECONDS: DateTime.TIME_24_WITH_SECONDS,
  DATETIME_SHORT: DateTime.DATETIME_SHORT,
  DATETIME_MED: DateTime.DATETIME_MED,
  DATETIME_FULL: DateTime.DATETIME_FULL,
  DATETIME_SHORT_WITH_SECONDS: DateTime.DATETIME_SHORT_WITH_SECONDS,
} as const

type DateTimeFormats = (typeof FORMAT)[keyof typeof FORMAT]

export {
  dateTime,
  configureDateTime,
  formatDateTime,
  formateDateTimeString,
  dateTimeFromSeconds,
  toTimestamp,
  stringToTimeStamp,
  dateTimeUtc,
  dateStringToTimeStamp,
  createDateTimeFromDateAndTime,
  toFormat,
  timestampToDateTime,
  LOCALE_FORMATS,
  FORMAT,
}
export type { DateTimeConfig, DateTimeFormats }
