import React from 'react'
import { Link } from 'react-router-dom'

import './link.css'

interface Props {
  to: string
  id?: string
  rel?: string
  label?: string
  target?: string
  underline?: boolean

  children?: JSX.Element | string
}
export const LinkComponent = ({
  to,
  id,
  rel,
  target,
  label,
  children,
  underline = true,
}: Props) => {
  return (
    <Link
      className={`${underline ? 'link--underline' : ''} link__container`}
      to={to}
      id={id}
      rel={rel}
      target={target}
    >
      {label ?? children}
    </Link>
  )
}
