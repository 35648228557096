import { call, put, takeLatest } from 'redux-saga/effects'

import { SupplierSvcPromiseClient } from '../../proto/booking/v1/supplier_grpc_web_pb'
import * as supplierv1 from '../../proto/booking/v1/supplier_pb'

import {
  Actions,
  CREATE_REQ,
  CREATE_SERVICE_REQ,
  DELETE_REQ,
  DELETE_SERVICE_REQ,
  EDIT_REQ,
  EDIT_SERVICE_REQ,
  LIST_CONFIGS_REQ,
  LIST_REQ,
  SET_CONFIG_REQ,
} from '../../store/booking/supplier/actions'
import { Actions as NotificationActions } from '../../store/notification/actions'

import { authMetadata } from '../../helpers/auth'

export function* list(client: SupplierSvcPromiseClient) {
  try {
    const req = new supplierv1.ListSuppliersRequest()
    const resp: supplierv1.ListSuppliersResponse = yield call(
      [client, client.listSuppliers],
      req,
      authMetadata(),
    )

    yield put(Actions.listResp(resp.getTotalCount(), resp.getSuppliersList()))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listErr(err))
  }
}

export function* create(
  client: SupplierSvcPromiseClient,
  action: ReturnType<typeof Actions.createReq>,
) {
  try {
    const { supplier, disablePricing } = action.payload
    const req = new supplierv1.CreateSupplierRequest()
    req.setSupplier(supplier)
    req.setDisablePricing(disablePricing)
    const resp: supplierv1.CreateSupplierResponse = yield call(
      [client, client.createSupplier],
      req,
      authMetadata(),
    )
    const createdSupplier = resp.getSupplier()
    if (!createdSupplier) {
      throw new Error('missing supplier')
    }
    if (createdSupplier.getOwner()?.getOrganizationId()) {
      yield put(Actions.listConfigsReq())
    }

    yield put(Actions.createResp(createdSupplier))
    yield put(
      NotificationActions.send({
        key: `supplier-${createdSupplier.getSupplierRef()}`,
        kind: 'success',
        message: 'Supplier Created',
        description: 'The supplier was created successfully.',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.createErr(err))
  }
}

export function* edit(
  client: SupplierSvcPromiseClient,
  action: ReturnType<typeof Actions.editReq>,
) {
  try {
    const { supplier } = action.payload
    const req = new supplierv1.EditSupplierRequest()
    req.setSupplier(supplier)
    const resp: supplierv1.EditSupplierResponse = yield call(
      [client, client.editSupplier],
      req,
      authMetadata(),
    )
    const editedSupplier = resp.getSupplier()
    const deactivatedSupplier = resp.getDeactivatedSupplier()
    if (!editedSupplier) {
      throw new Error('missing supplier')
    }
    yield put(Actions.editResp(editedSupplier, deactivatedSupplier))
    yield put(Actions.listConfigsReq()) // update configs to reflect the latest state.
    yield put(
      NotificationActions.send({
        key: `supplier-${editedSupplier.getSupplierRef()}`,
        kind: 'success',
        message: 'Supplier Updated',
        description: 'The supplier has been updated.',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.editErr(err))
  }
}

export function* deleteSupplier(
  client: SupplierSvcPromiseClient,
  action: ReturnType<typeof Actions.deleteReq>,
) {
  try {
    const { supplierID } = action.payload
    const req = new supplierv1.DeleteSupplierRequest()
    req.setSupplierId(supplierID)
    yield call([client, client.deleteSupplier], req, authMetadata())
    yield put(Actions.deleteResp(supplierID))
    yield put(
      NotificationActions.send({
        key: 'supplier-deleted',
        kind: 'success',
        message: 'Supplier Deleted',
        description: 'The supplier has been deleted.',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.deleteErr(err))
  }
}

export function* createService(
  client: SupplierSvcPromiseClient,
  action: ReturnType<typeof Actions.createServiceReq>,
) {
  try {
    const { service } = action.payload
    const req = new supplierv1.CreateSupplierServiceRequest()
    req.setService(service)
    const resp: supplierv1.CreateSupplierServiceResponse = yield call(
      [client, client.createSupplierService],
      req,
      authMetadata(),
    )
    const createdService = resp.getService()
    if (!createdService) {
      throw new Error('missing supplier service')
    }
    yield put(Actions.createServiceResp(createdService))
    // hack to refresh data which otherwise would require to refersh the page
    // to see the latest changes
    yield put(Actions.listReq())
    yield put(
      NotificationActions.send({
        key: `supplier-service-${createdService.getServiceId()}`,
        kind: 'success',
        message: 'Supplier Service Created',
        description: 'The supplier service was created successfully.',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.createServiceErr(err))
  }
}

export function* editService(
  client: SupplierSvcPromiseClient,
  action: ReturnType<typeof Actions.editServiceReq>,
) {
  try {
    const { service } = action.payload
    const req = new supplierv1.EditSupplierServiceRequest()
    req.setService(service)
    const resp: supplierv1.EditSupplierServiceResponse = yield call(
      [client, client.editSupplierService],
      req,
      authMetadata(),
    )
    const editedService = resp.getService()
    const deactivatedService = resp.getDeactivatedService()
    if (!editedService) {
      throw new Error('missing supplier service')
    }
    yield put(Actions.editServiceResp(editedService, deactivatedService))
    yield put(
      NotificationActions.send({
        key: `supplier-service-${editedService.getServiceId()}`,
        kind: 'success',
        message: 'Supplier Service Updated',
        description: 'The supplier service has been updated.',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.editServiceErr(err))
  }
}

export function* deleteService(
  client: SupplierSvcPromiseClient,
  action: ReturnType<typeof Actions.deleteServiceReq>,
) {
  try {
    const { serviceID } = action.payload
    const req = new supplierv1.DeleteSupplierServiceRequest()
    req.setServiceId(serviceID)
    yield call([client, client.deleteSupplierService], req, authMetadata())
    yield put(Actions.deleteServiceResp(serviceID))
    yield put(
      NotificationActions.send({
        key: 'supplier-service-deleted',
        kind: 'success',
        message: 'Supplier Service Deleted',
        description: 'The supplier service has been deleted.',
        dismissAfter: 4500,
      }),
    )
  } catch (err) {
    if (err instanceof Error) yield put(Actions.deleteServiceErr(err))
  }
}

export function* listConfigs(client: SupplierSvcPromiseClient) {
  try {
    const req = new supplierv1.ListConfigsRequest()
    const resp: supplierv1.ListConfigsResponse = yield call(
      [client, client.listConfigs],
      req,
      authMetadata(),
    )
    yield put(Actions.listConfigsResp(resp.getTotalCount(), resp.getConfigsList()))
  } catch (err) {
    if (err instanceof Error) yield put(Actions.listConfigsErr(err))
  }
}

export function* setConfig(
  client: SupplierSvcPromiseClient,
  action: ReturnType<typeof Actions.setConfigReq>,
) {
  try {
    const { supplierConfig } = action.payload
    const req = new supplierv1.SetSupplierConfigRequest()
    req.setConfig(supplierConfig)
    const resp: supplierv1.SetSupplierConfigResponse = yield call(
      [client, client.setConfig],
      req,
      authMetadata(),
    )
    const editedSupplierConfig = resp.getConfig()
    if (!editedSupplierConfig) {
      throw new Error('missing supplier Config')
    }
    yield put(Actions.setConfigResp(editedSupplierConfig))
    yield put(
      NotificationActions.send({
        key: `org-${editedSupplierConfig.getOwner()?.getOrganizationId()}`,
        kind: 'success',
        message: 'Supplier Config Updated',
        description: 'The supplier Configuration has been updated.',
        dismissAfter: 4500,
      }),
    )
    yield put(Actions.listConfigsReq())
  } catch (err) {
    if (err instanceof Error) yield put(Actions.setConfigErr(err))
  }
}

export default function* sagas() {
  const client = new SupplierSvcPromiseClient('')

  yield takeLatest(LIST_REQ, list, client)
  yield takeLatest(CREATE_REQ, create, client)
  yield takeLatest(EDIT_REQ, edit, client)
  yield takeLatest(DELETE_REQ, deleteSupplier, client)
  yield takeLatest(CREATE_SERVICE_REQ, createService, client)
  yield takeLatest(EDIT_SERVICE_REQ, editService, client)
  yield takeLatest(DELETE_SERVICE_REQ, deleteService, client)
  yield takeLatest(LIST_CONFIGS_REQ, listConfigs, client)
  yield takeLatest(SET_CONFIG_REQ, setConfig, client)
}
