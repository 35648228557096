import React from 'react'

import Search from 'antd/es/input/Search'

import { getInputClassName } from '../helpers/Input.helper'

import { InputCommonProps } from '../types/InputCommonProps.interface'
import { INPUTSIZES } from '../types/InputSize.type'

interface SearchProps extends InputCommonProps {
  placeholder: string
  onChange: (value: string) => void
  key?: string
}

export const SearchComponent = ({
  placeholder,
  key,
  size = INPUTSIZES.extraLarge,
  overrideWidth,
  onChange,
}: SearchProps) => {
  return (
    <Search
      key={key}
      placeholder={placeholder}
      onSearch={onChange}
      className={overrideWidth ? '' : getInputClassName(size)}
    />
  )
}
