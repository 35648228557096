import { INominatedContract } from 'shared/interface/nominated-contract.interface'

import { NominatedContract } from 'proto/contract/v1/contract_pb'

import {
  fromConfiguredSurchargeProto,
  toConfiguredSurchargeProto,
} from './configured-surcharge.mapper'

const fromNominatedContractProto = (contract: NominatedContract): INominatedContract => {
  return {
    contractName: contract.getContractName(),
    contractRef: contract.getContractRef(),
    currency: contract.getCurrency(),
    laneId: contract.getLaneId(),
    configuredSurchargeList: contract
      .getConfiguredSurchargesList()
      .map((s) => fromConfiguredSurchargeProto(s)),
    freightPrice: contract.getFreightPrice(),
    totalPrice: contract.getTotalPrice(),
    acceptedBy: contract.getAcceptedBy(),
  }
}

const toNominatedContractProto = (contract: INominatedContract): NominatedContract => {
  const proto = new NominatedContract()
  proto.setContractName(contract.contractName)
  proto.setContractRef(contract.contractRef)
  proto.setCurrency(contract.currency)
  proto.setLaneId(contract.laneId)
  proto.setConfiguredSurchargesList(
    contract.configuredSurchargeList.map(toConfiguredSurchargeProto),
  )
  proto.setFreightPrice(contract.freightPrice)
  proto.setTotalPrice(contract.totalPrice)
  return proto
}

export { fromNominatedContractProto, toNominatedContractProto, toConfiguredSurchargeProto }
