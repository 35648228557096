import React, { useEffect } from 'react'

import { Form, FormInstance, Radio } from 'antd'
import { Rule } from 'antd/es/form'

import './RadioGroup.css'

type RadioOptions<T> = {
  label: JSX.Element | string | number
  value: T
  key: string | number
  style?: object
}

interface RadioGroupProps<T> {
  options: RadioOptions<T>[]
  label: string
  required?: boolean
  value?: string | number
  name: string
  formRef: FormInstance<any>
  rules?: Rule[]
  onChange: (value: T) => void
}

/**
 * A radio group component that uses buttons.
 */
export const ButtonRadioGroup = <T extends string | number>({
  options,
  label,
  required,
  value,
  name,
  formRef,
  rules,
  onChange,
}: RadioGroupProps<T>) => {
  useEffect(() => {
    if (formRef && name) {
      formRef.setFieldsValue({
        [name]: value,
      })
    }
  }, [, value])

  return (
    <Form.Item label={label} required={required} name={name} rules={rules}>
      <div className="radio-group__container">
        {options.map((option) => {
          return (
            <Radio.Button
              className={
                (value === option.value ? ' radio-group__button--selected' : '') +
                ' radio-group__button'
              }
              checked={value === option.value}
              onChange={() => onChange(option.value)}
              style={{ ...option.style }}
            >
              <div>{option.label}</div>
            </Radio.Button>
          )
        })}
      </div>
    </Form.Item>
  )
}
