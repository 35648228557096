import './headingComponent.css'

export type Heading = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
type KeysOfHeading<T> = { [HEADING in Heading]: T }
export type HeadingSize = 'small' | 'normal' | 'medium' | 'large'
type KeysOfHeadingSize = { [HEADINGSIZE in HeadingSize]: number }
interface HeadingProps {
  text: string
  headingType?: Heading
  fontweight?: 'normal' | 'bold'
  fontSize?: HeadingSize
}
export const Heading = ({
  text,
  headingType = 'h1',
  fontweight = 'normal',
  fontSize = 'medium',
}: HeadingProps) => {
  const sizeMap: KeysOfHeadingSize = {
    large: 24,
    medium: 20,
    normal: 16,
    small: 12,
  }

  const getHeader = (text: string, headingType: Heading) => {
    const headingMap: KeysOfHeading<JSX.Element> = {
      ['h1']: (
        <h1
          className="heading--margin"
          style={{ fontWeight: fontweight, fontSize: sizeMap[fontSize] }}
        >
          {text}
        </h1>
      ),
      ['h2']: (
        <h2
          className="heading--margin"
          style={{ fontWeight: fontweight, fontSize: sizeMap[fontSize] }}
        >
          {text}
        </h2>
      ),
      ['h3']: (
        <h3
          className="heading--margin"
          style={{ fontWeight: fontweight, fontSize: sizeMap[fontSize] }}
        >
          {text}
        </h3>
      ),
      ['h4']: (
        <h4
          className="heading--margin"
          style={{ fontWeight: fontweight, fontSize: sizeMap[fontSize] }}
        >
          {text}
        </h4>
      ),
      ['h5']: (
        <h5
          className="heading--margin"
          style={{ fontWeight: fontweight, fontSize: sizeMap[fontSize] }}
        >
          {text}
        </h5>
      ),
      ['h6']: (
        <h6
          className="heading--margin"
          style={{ fontWeight: fontweight, fontSize: sizeMap[fontSize] }}
        >
          {text}
        </h6>
      ),
    }
    return headingMap[headingType]
  }

  return getHeader(text, headingType)
}
