import React from 'react'

import { IconMap, IconTypes } from './IconsMap'

interface IconProps {
  icon: IconTypes
}
/** For ant design icons */
export const IconComponent = ({ icon }: IconProps) => {
  return <>{IconMap[icon]}</>
}
