import React, { useEffect, useId } from 'react'

import { Form, FormInstance, Radio, Space } from 'antd'
import { Rule } from 'antd/lib/form'

import './RadioGroup.css'

type RadioOptions<T> = {
  label: string | number
  value: T
  key: string | number
}

interface RadioGroupProps<T> {
  options: RadioOptions<T>[]
  label: string
  required?: boolean
  value?: string | number
  name: string
  formRef: FormInstance<any>
  rules?: Rule[]
  disabled?: boolean
  onChange: (value: T) => void
}

/**
 * A regular radio group component that uses circular radio buttons.
 */
export const RadioGroupComponent = <T extends string | number>({
  options,
  label,
  required,
  value,
  name,
  formRef,
  rules,
  disabled,
  onChange,
}: RadioGroupProps<T>) => {
  const id = useId()
  useEffect(() => {
    if (formRef && name) {
      formRef.setFieldsValue({
        [name]: value,
      })
    }
  }, [, value])

  return (
    <Form.Item label={label} required={required} name={name} rules={rules}>
      <Radio.Group
        key={id}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        disabled={disabled}
      >
        <Space direction="vertical">
          {options.map(({ key, label, value }) => {
            return (
              <Radio key={id + ':' + key} value={value}>
                {label}
              </Radio>
            )
          })}
        </Space>
      </Radio.Group>
    </Form.Item>
  )
}
