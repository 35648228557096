import React from 'react'

import { Form } from 'antd'
import { Rule } from 'antd/es/form'
import { FormInstance } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'

import { runRules } from '../helpers/CustomValidator.helper'
import { getInputClassName } from '../helpers/Input.helper'

import { InputCommonProps } from '../types/InputCommonProps.interface'
import { INPUTSIZES } from '../types/InputSize.type'

interface TextAreaProps extends InputCommonProps {
  label: string
  value: string
  disabled?: boolean
  nrOfRows?: number
  maxNrOfRows?: number
  placeholder: string
  required?: boolean
  onChange: (value: string) => void
  formRef?: FormInstance<any>
  maxNrOfChars?: number
  formName?: string
  showCount?: boolean
  rules?: Rule[]
}
export const TextAreaComponent = ({
  label,
  value,
  disabled,
  nrOfRows = 2,
  maxNrOfRows,
  placeholder,
  required,
  formRef,
  formName,
  size = INPUTSIZES.extraLarge,
  maxNrOfChars = 2000,
  overrideWidth,
  showCount = false,
  rules,
  onChange,
}: TextAreaProps) => {
  if (formRef && formName && value) {
    formRef.setFieldsValue({ [formName]: value })
  }

  return (
    <Form.Item
      label={label}
      required={required}
      name={formName}
      rules={[
        {
          validator() {
            return runRules(rules)
          },
        },
      ]}
    >
      <TextArea
        autoSize={{ minRows: nrOfRows, maxRows: maxNrOfRows }}
        maxLength={maxNrOfChars}
        showCount={showCount}
        style={{ width: overrideWidth }}
        className={overrideWidth ? '' : getInputClassName(size)}
        disabled={disabled}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        value={value}
      />
    </Form.Item>
  )
}
