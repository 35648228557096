import React from 'react'

import Icon, { FileTextFilled } from '@ant-design/icons'
import { DiffFilled } from '@ant-design/icons'
import { MenuProps } from 'antd'
import { LDFlagSet } from 'launchdarkly-react-client-sdk'
import { RouteNames, requiredRolesRouteMap } from 'route/routes-map'

import { User } from 'proto/iam/v1/user_pb'

import * as Icons from '../components/icons'
import { LinkComponent } from 'components/Links/Link'

import { canSeeShipments } from 'helpers/rolePermissions'
import { containRole } from 'helpers/user'

export type MenuItem = Required<MenuProps>['items'][number]

export function getItem(
  label: string,
  key: string,
  icon: React.ReactNode,
  path?: string,
  children?: MenuItem[],
  onClick?: () => void,
): MenuItem {
  return {
    key,
    icon,
    children,
    label: path ? (
      <LinkComponent underline={false} to={path}>
        {label}
      </LinkComponent>
    ) : (
      label
    ),
    onClick,
  } as MenuItem
}

const iconStyle = {
  fontSize: '16px',
  marginRight: '6px',
  transition: 'none',
}

export const HomeMenuItem = () =>
  getItem('Home', '/', <Icon component={Icons.Building} style={iconStyle} />, RouteNames.Home)

export const OrdersMenuItem = (roles: User.Role[], featureFlags?: LDFlagSet): MenuItem => {
  if (featureFlags?.orderFlow || containRole([User.Role.TRANSPORTER], roles)) {
    return {
      label: 'Order',
      key: 'order-menu',
      icon: <Icon component={Icons.StickyNote} style={iconStyle} />,
      children: [
        getItem('Orders', '/orders', <FileTextFilled style={iconStyle} />, RouteNames.Orders),
        getItem(
          'Products',
          '/products',
          <Icon component={Icons.Tag} style={iconStyle} />,
          RouteNames.Products,
        ),
      ],
    }
  }
  return null
}

export const BookingsMenuItem = () =>
  getItem(
    'Bookings',
    '/bookings',
    <Icon component={Icons.ClipboardList} style={iconStyle} />,
    RouteNames.Bookings,
  )

export const DraftsMenuItem = () =>
  getItem(
    'Drafts',
    '/drafts',
    <Icon component={Icons.Pencil} style={iconStyle} />,
    RouteNames.Drafts,
  )

export const QuotesMenuItem = (featureFlags?: LDFlagSet) => {
  if (featureFlags?.quotesFe) {
    return getItem(
      'Quotes',
      '/quotes',
      <Icon component={Icons.Coins} style={iconStyle} />,
      RouteNames.Quotes,
    )
  }
  return null
}

export const ContractsMenuItem = (roles: User.Role[], featureFlags?: LDFlagSet) => {
  if (
    featureFlags?.contractsFe &&
    containRole([User.Role.TRANSPORTER, User.Role.MANAGER, User.Role.ADMIN], roles)
  ) {
    return getItem(
      'Contracts',
      '/contracts',
      <DiffFilled style={iconStyle} />,
      RouteNames.Contracts,
    )
  }
  return null
}

export const ShipmentsMenuItem = (roles: User.Role[]) => {
  if (canSeeShipments(roles)) {
    return getItem(
      'Shipments',
      '/shipments',
      <Icon component={Icons.Boxes} style={iconStyle} />,
      RouteNames.Shipments,
    )
  }
  return null
}

export const InvoicesMenuItem = (roles: User.Role[]) => {
  if (containRole([User.Role.ORGANIZATION], roles)) {
    return getItem(
      'Invoices',
      '/invoices',
      <Icon component={Icons.InvoiceDollar} style={iconStyle} />,
      RouteNames.Invoices,
    )
  }
  return null
}

export const FinanceMenuItem = (roles: User.Role[]): MenuItem => {
  if (containRole([User.Role.TRANSPORTER, User.Role.FINANCE], roles)) {
    return {
      label: 'Finance',
      key: '/finance',
      icon: <Icon component={Icons.Coins} style={iconStyle} />,
      children: [
        getItem(
          'Invoices',
          '/invoices',
          <Icon component={Icons.InvoiceDollar} style={iconStyle} />,
          RouteNames.Invoices,
        ),
        getItem(
          'Costs',
          '/costs',
          <Icon component={Icons.Coins} style={iconStyle} />,
          RouteNames.Costs,
        ),
        getItem(
          'Profitability',
          '/profitability',
          <Icon component={Icons.InvoiceDollar} style={iconStyle} />,
          RouteNames.Profatibility,
        ),
        getItem(
          'Article types',
          '/article-types',
          <Icon component={Icons.Coins} style={iconStyle} />,
          RouteNames.ArticeTypes,
        ),
      ],
    }
  }
  return null
}

export const AddressesMenuItem = () => {
  return getItem(
    'Addresses',
    '/addresses',
    <Icon component={Icons.AddressBook} style={iconStyle} />,
    RouteNames.Addressess,
  )
}

export const ReportsMenuItem = (roles: User.Role[], featureFlags?: LDFlagSet) => {
  if (
    featureFlags?.reportsFe &&
    containRole([User.Role.ORGANIZATION, User.Role.TRANSPORTER], roles)
  ) {
    return getItem(
      'Reports',
      '/reports',
      <Icon component={Icons.Poll} style={iconStyle} />,
      RouteNames.Reports,
    )
  }
  return null
}

export const AdminMenuItem = (roles: User.Role[]) => {
  if (containRole([User.Role.TRANSPORTER, User.Role.MANAGER, User.Role.ADMIN], roles)) {
    const requiermentsForMonitor = requiredRolesRouteMap[RouteNames.AdminMonitor]
    const adminNav = requiermentsForMonitor.requiredRoles?.some((role) => {
      return containRole([role], roles)
    })
    const targetAdminPage = adminNav ? RouteNames.AdminMonitor : RouteNames.AdminUsers
    if (targetAdminPage) {
      return getItem(
        'Admin',
        '/admin',
        <Icon component={Icons.UserCog} style={iconStyle} />,
        targetAdminPage,
      )
    }
  }
  return null
}

export const SurchargesMenuItem = (roles: User.Role[], featureFlags?: LDFlagSet) => {
  const requiredRoles = requiredRolesRouteMap[RouteNames.Surcharges]

  const userHasRoles = requiredRoles.requiredRoles?.some((role) => {
    return containRole([role], roles)
  })

  if (!featureFlags?.surchargeFe || !userHasRoles) {
    return null
  }
  return getItem(
    'Surcharges',
    '/surcharges',
    <Icon component={Icons.ClipboardList} style={iconStyle} />,
    RouteNames.Surcharges,
  )
}

/*---- Only for Navbar - menu on mobile ----*/

export const ProfileMenuItem = () => {
  return getItem(
    'Profile',
    '/profile',
    <Icon component={Icons.ProfileUser} style={iconStyle} />,
    RouteNames.Profile,
  )
}

export const PrivacyMenuItem = () => {
  return getItem(
    'Privacy',
    '/privacy',
    <Icon component={Icons.InfoCircle} style={iconStyle} />,
    RouteNames.Privacy,
  )
}

/*------------------------------------------*/

export const SignOutMenuItem = (onLogout: () => void) => {
  return getItem(
    'Sign out',
    '/logout',
    <Icon component={Icons.SignOut} style={iconStyle} />,
    undefined,
    undefined,
    onLogout,
  )
}

export const keyFromPath = (path: string) => {
  const routes = [
    '/orders',
    '/bookings',
    '/drafts',
    '/quotes',
    '/shipments',
    '/reports',
    '/admin',
    '/contracts',
    '/surcharges',
  ]

  const foundRoute = routes.find((route) => path.includes(route))
  return foundRoute ? foundRoute : path
}
