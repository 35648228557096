import React, { useEffect } from 'react'

import { DatePicker, Form } from 'antd'
import { Rule } from 'antd/es/form'
import { FormInstance } from 'antd/es/form/Form'
import { DateTime } from 'luxon'
import luxonGenerateConfig from 'rc-picker/es/generate/luxon'
import { DisabledDate } from 'rc-picker/es/interface'

import { getFormItemValidator, runCustomvalidators } from '../helpers/CustomValidator.helper'
import { getInputClassName } from '../helpers/Input.helper'
import { dateTime } from 'shared/helpers/datetime.helper'

import { InputCommonProps } from '../types/InputCommonProps.interface'
import { INPUTSIZES } from '../types/InputSize.type'

import './Input.css'

interface DatePickerProps extends InputCommonProps {
  value: string
  formItemName?: string
  allowClear?: boolean
  pickerType?: 'week' | 'month' | 'quarter' | 'year' | 'date'
  placeholder?: string
  formRef?: FormInstance<any>
  customDateValidators?: Array<() => string | undefined>
  required?: boolean
  rules?: Rule[]
  disabledDate?: DisabledDate<DateTime<boolean>> | undefined
  onChange?: ((date: DateTime<boolean>, dateString: string | string[]) => void) | undefined
}

const config = {
  ...luxonGenerateConfig,
}

const MyDatePicker = DatePicker.generatePicker<DateTime>(config)

/**
 * Usage: Picks date.
 */
export const DatePickerComponent = ({
  value,
  disabled,
  label,
  formItemName,
  placeholder = 'Select date',
  allowClear = false,
  pickerType = 'week',
  overrideWidth,
  size = INPUTSIZES.medium,
  formRef,
  customDateValidators,
  rules,
  required = false,
  disabledDate,
  onChange,
}: DatePickerProps) => {
  useEffect(() => {
    if (formRef && formItemName && value) {
      formRef.setFieldsValue({
        [formItemName]: value.length > 0 ? dateTime(value) : '',
      })
    }
  }, [value])

  const validateDate = (): string | undefined => {
    return customDateValidators ? runCustomvalidators(customDateValidators) : undefined
  }

  return formRef ? (
    <Form.Item
      name={formItemName}
      label={label}
      rules={rules ?? [getFormItemValidator(validateDate)]}
      required={required}
    >
      <MyDatePicker
        className={overrideWidth ? '' : getInputClassName(size)}
        disabled={disabled}
        disabledDate={disabledDate}
        placeholder={placeholder}
        format="YYYY-MM-DD"
        allowClear={allowClear}
        picker={pickerType}
        onChange={onChange}
      />
    </Form.Item>
  ) : (
    <MyDatePicker
      className={overrideWidth ? '' : getInputClassName(size)}
      disabled={disabled}
      disabledDate={disabledDate}
      placeholder={placeholder}
      format="YYYY-MM-DD"
      allowClear={allowClear}
      picker={pickerType}
      onChange={onChange}
    />
  )
}
