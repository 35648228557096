import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import * as commonProvider from '../../proto/common/provider_pb'
import { ArticleTypeServicePromiseClient } from '../../proto/invoicing/v1/articletype_grpc_web_pb'
import * as articleTypev1 from '../../proto/invoicing/v1/articletype_pb'
import { ProviderServicePromiseClient } from '../../proto/invoicing/v1/provider_grpc_web_pb'
import * as providerv1 from '../../proto/invoicing/v1/provider_pb'

import {
  Actions,
  CREATE_ARTICLE_TYPE_REQ,
  DELETE_ACCOUNTING_NUMBER_REQ,
  DELETE_ARTICLE_TYPE_REQ,
  LIST_ACCOUNTING_NUMBERS_REQ,
  LIST_ARTICLE_TYPES_REQ,
  SET_ACCOUNTING_NUMBER_REQ,
  UPDATE_ARTICLE_TYPE_REQ,
} from '../../store/invoicing/articleTypes/actions'
import { Actions as NotificationActions } from '../../store/notification/actions'

import { authMetadata } from '../../helpers/auth'

export function* list(
  client: ArticleTypeServicePromiseClient,
  action: ReturnType<typeof Actions.listArticleTypesReq>,
) {
  try {
    const { page, branchCountryID, customerCountryID, fetchAccounting } = action.payload
    if (fetchAccounting) {
      yield put(Actions.listAccountingNumbersReq())
    }
    const req = new articleTypev1.ListArticleTypesRequest()

    if (page.filter) {
      const filter = new articleTypev1.ArticleTypeFilter()
      if (page.filter.category && page.filter.category.length > 0) {
        filter.setCategoryList(page.filter.category)
      }
      if (page.filter.domesticVat !== undefined) {
        filter.setDomesticVatList([page.filter.domesticVat])
      }
      if (page.filter.euVat !== undefined) {
        filter.setEuVatList([page.filter.euVat])
      }
      if (page.filter.automation !== undefined) {
        filter.setUseAutomationList([page.filter.automation])
      }
      req.setFilter(filter)
    }

    if (page.sorting) {
      const sorting = new articleTypev1.ArticleTypeSorting()
      sorting.setSortingField(page.sorting.getSortingField())
      sorting.setOrdering(page.sorting.getOrdering())
      req.setSorting(sorting)
    }

    if (branchCountryID && customerCountryID) {
      req.setBranchCountryId(branchCountryID)
      req.setCustomerCountryId(customerCountryID)
    }

    const resp: articleTypev1.ListArticleTypesResponse = yield call(
      [client, client.listArticleTypes],
      req,
      authMetadata(),
    )
    yield put(Actions.listArticleTypesResp(resp.getArticleTypesList(), resp.getTotalCount(), page))
  } catch (err: any) {
    yield put(Actions.listArticleTypesErr(err))
  }
}

function* listAccountingNumbers(client: ProviderServicePromiseClient) {
  try {
    const req = new providerv1.ListAccountingRequest()
    req.setProviderType(commonProvider.ProviderType.VISMA)
    const resp: providerv1.ListAccountingResponse = yield call(
      [client, client.listAccounting],
      req,
      authMetadata(),
    )
    const result = resp.getAccountingNumbersList()
    if (!result) {
      throw new Error('Could not fetch accounting numbers')
    }
    yield put(Actions.listAccountingNumbersResp(result))
  } catch (err: any) {
    yield put(Actions.listAccountingNumbersErr(err))
  }
}

export function* createArticleType(
  client: ArticleTypeServicePromiseClient,
  action: ReturnType<typeof Actions.createArticleTypeReq>,
) {
  try {
    const { accountingArticle, page } = action.payload

    const req = new articleTypev1.CreateArticleTypeRequest()
    req.setArticleType(accountingArticle.articleType)

    const resp: articleTypev1.CreateArticleTypeResponse = yield call(
      [client, client.createArticleType],
      req,
      authMetadata(),
    )
    const result = resp.getArticleType()
    if (!result) {
      throw new Error('Could not create article type')
    }
    yield put(
      NotificationActions.send({
        key: `article-type-${result.getArticleNo()}`,
        kind: 'success',
        message: 'Article Type Created',
        description: 'Article type was saved successfully',
        dismissAfter: 4500,
      }),
    )

    if (accountingArticle.accountingNumber) {
      yield put(Actions.setAccountingNumberReq(accountingArticle.accountingNumber))
    }

    yield put(Actions.createArticleTypeResp(result, page))
  } catch (err: any) {
    yield put(Actions.listArticleTypesErr(err))
  }
}
export function* setAccountingNumber(
  client: ProviderServicePromiseClient,
  action: ReturnType<typeof Actions.setAccountingNumberReq>,
) {
  try {
    const { accountingNumber } = action.payload
    const req = new providerv1.SetAccountingRequest()
    req.setAccountingNumber(accountingNumber)
    req.setProviderType(commonProvider.ProviderType.VISMA)

    const resp: providerv1.SetAccountingRequest = yield call(
      [client, client.setAccounting],
      req,
      authMetadata(),
    )
    const result = resp.getAccountingNumber()
    if (!result) {
      throw new Error('Could not create article number')
    }
    yield put(Actions.setAccountingNumberResp(result))
  } catch (err: any) {
    yield put(Actions.setAccountingNumberErr(err))
  }
}

export function* updateArticleType(
  client: ArticleTypeServicePromiseClient,
  action: ReturnType<typeof Actions.updateArticleTypeReq>,
) {
  try {
    const { accountingArticle } = action.payload

    const req = new articleTypev1.UpdateArticleTypeRequest()
    req.setArticleType(accountingArticle.articleType)

    const resp: articleTypev1.UpdateArticleTypeResponse = yield call(
      [client, client.updateArticleType],
      req,
      authMetadata(),
    )
    const result = resp.getArticleType()
    if (!result) {
      throw new Error('Could not update article type')
    }
    yield put(
      NotificationActions.send({
        key: `article-type-${result.getArticleNo()}`,
        kind: 'success',
        message: 'Article Type Updated',
        description: 'Article type was updated successfully',
        dismissAfter: 4500,
      }),
    )
    if (accountingArticle.accountingNumber) {
      yield put(Actions.setAccountingNumberReq(accountingArticle.accountingNumber))
    }
    yield put(Actions.updateArticleTypeResp(result))
  } catch (err: any) {
    yield put(Actions.updateArticleTypeErr(err))
  }
}

export function* deleteArticleType(
  client: ArticleTypeServicePromiseClient,
  action: ReturnType<typeof Actions.deleteArticleTypeReq>,
) {
  try {
    const { articleNo } = action.payload

    const req = new articleTypev1.DeleteArticleTypeRequest()
    req.setArticleNo(articleNo)

    const resp: articleTypev1.DeleteArticleTypeResponse = yield call(
      [client, client.deleteArticleType],
      req,
      authMetadata(),
    )

    const result = resp.getArticleNo()
    if (!result) {
      throw new Error('Could not delete article type')
    }

    yield put(
      NotificationActions.send({
        key: `article-type-${result}`,
        kind: 'success',
        message: 'Article Type Deleted',
        description: 'Article type was successfully deleted',
        dismissAfter: 4500,
      }),
    )
    yield put(Actions.deleteAccountingNumberReq(articleNo))
    yield put(Actions.deleteArticleTypeResp(result))
  } catch (err: any) {
    yield put(Actions.deleteArticleTypeErr(err))
  }
}
export function* deleteAccountingNumber(
  client: ProviderServicePromiseClient,
  action: ReturnType<typeof Actions.deleteAccountingNumberReq>,
) {
  try {
    const { articleNo } = action.payload

    const req = new providerv1.DeleteAccountingRequest()
    req.setArticleNo(articleNo)
    req.setProviderType(commonProvider.ProviderType.VISMA)

    const resp: providerv1.DeleteAccountingResponse = yield call(
      [client, client.deleteAccounting],
      req,
      authMetadata(),
    )

    const result = resp.getArticleNo()
    if (!result) {
      throw new Error('Could not delete accounting number')
    }

    yield put(Actions.deleteAccountingNumberResp(result))
  } catch (err: any) {
    yield put(Actions.deleteAccountingNumberErr(err))
  }
}

export default function* sagas() {
  const client = new ArticleTypeServicePromiseClient('')
  const providerClient = new ProviderServicePromiseClient('')

  yield takeLatest(CREATE_ARTICLE_TYPE_REQ, createArticleType, client)
  yield takeEvery(DELETE_ARTICLE_TYPE_REQ, deleteArticleType, client)
  yield takeEvery(LIST_ARTICLE_TYPES_REQ, list, client)
  yield takeEvery(UPDATE_ARTICLE_TYPE_REQ, updateArticleType, client)

  yield takeLatest(SET_ACCOUNTING_NUMBER_REQ, setAccountingNumber, providerClient)
  yield takeEvery(DELETE_ACCOUNTING_NUMBER_REQ, deleteAccountingNumber, providerClient)
  yield takeEvery(LIST_ACCOUNTING_NUMBERS_REQ, listAccountingNumbers, providerClient)
}
