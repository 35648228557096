import * as React from 'react'

import Icon, { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'

import { IconButton } from 'components/Buttons'

import { AngleDown, AngleUp } from '../icons'
import './style.css'

interface Props extends React.HTMLProps<HTMLDivElement> {
  expanded: boolean
  onExpandToggle: () => void
  red?: boolean
}

export const ToggleNotch: React.FC<Props> = ({ expanded, onExpandToggle, red, ...props }) => {
  return (
    <div className="wrapper" {...props}>
      <div className={`notch ${red ? 'red' : ''}`} onClick={onExpandToggle}></div>
      <Icon
        className={`angle-icon ${red ? 'red' : ''}`}
        component={expanded ? AngleUp : AngleDown}
        onClick={onExpandToggle}
      />
    </div>
  )
}

interface PropsInfo extends React.HTMLProps<HTMLDivElement> {
  expanded: boolean
  onExpandToggle: () => void
}

export const InfoToggleNotch: React.FC<PropsInfo> = ({ expanded, onExpandToggle }) => {
  return (
    <IconButton
      onClick={onExpandToggle}
      icon={!expanded ? <InfoCircleOutlined /> : <CloseCircleOutlined />}
      title={!expanded ? 'Helper' : 'Close helper'}
    />
  )
}
